import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function Respondible_tech({ setFormData, formData, disabled, Loaddraft }) {
  console.log(formData)


  return (
    <Container fluid className='contractor-container'>
      <Row>
        {/* Left Column */}
        <Col lg={6}>
          <Form>
            {/* Search Section */}
            <Row className="mb-3">
              <Col md={10}>
                <Form.Group controlId="search">
                  <Form.Label>เลือกช่าง</Form.Label>
                  <Form.Control 
                    name="technicians_id"
                    disabled
                    value={`${formData?.lead_technician?.first_name} ${formData?.lead_technician?.last_name}`}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={2}>
                <Button variant="primary" className="mt-4" disabled>ค้นหา</Button>
              </Col>
            </Row>

            {/* Form Fields */}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="contractorId">
                  <Form.Label>รหัสช่าง</Form.Label>
                  <Form.Control type="text" placeholder="รหัสผู้รับเหมา"
                    name='technician_code'
                    value={formData?.lead_technician?.technician_id || ""}
                    disabled />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="phone">
                  <Form.Label>เบอร์โทรศัพท์</Form.Label>
                  <Form.Control type="text" placeholder="เบอร์โทรศัพท์"
                    name='tel'
                    value={formData?.lead_technician?.phone || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="contactName">
                  <Form.Label>ชื่อช่าง</Form.Label>
                  <Form.Control type="text" placeholder="ชื่อผู้รับเหมา"
                    name='name'
                    value={`${formData?.lead_technician?.first_name} ${formData?.lead_technician?.last_name}`}
                    disabled />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Email"
                    name='email'
                    value={formData?.lead_technician?.email || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>เลขผู้เสียภาษี</Form.Label>
                  <Form.Control type="text" placeholder="เลขผู้เสียภาษี"
                    name='citizen_id'
                    value={formData?.lead_technician?.tax_id || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>ที่อยู่</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="ที่อยู่"
                    name='address'
                    value={formData?.lead_technician?.address || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6}>
                <Form.Group controlId="province" className="mb-3">
                  <Form.Label>จังหวัด</Form.Label>
                  <Form.Control type="text"
                    name='address'
                    value={formData?.lead_technician?.province_name || ""}
                    disabled>

                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="district" className="mb-3">
                  <Form.Label>เขต/ตำบล</Form.Label>
                  <Form.Control type="text"
                    name='address'
                    value={formData?.lead_technician?.district_name || ""}
                    disabled>

                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="subdistrict" className="mb-3">
                  <Form.Label>แขวง/ตำบล</Form.Label>
                  <Form.Control type="text"
                    name='address'
                    value={formData?.lead_technician?.sub_district_name || ""}
                    disabled>

                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col lg={6}>
          <Form>
            {/* Search Section */}
            <Row className="mb-3">
              <Col md={10}>
                <Form.Group controlId="search">
                  <Form.Label>เลือกลูกน้องช่าง</Form.Label>
                  <Form.Control 
                    name="technicians_id"
                    value={`${formData?.assist_technician?.first_name} ${formData?.assist_technician?.last_name}`}
                    disabled
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={2}>
                <Button variant="primary" className="mt-4" disabled >ค้นหา</Button>
              </Col>
            </Row>

            {/* Form Fields */}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="contractorId">
                  <Form.Label>รหัสช่าง</Form.Label>
                  <Form.Control type="text" placeholder="รหัสผู้รับเหมา"
                    name='technician_code'
                    value={formData?.assist_technician?.technician_id || ""}
                    disabled />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="phone">
                  <Form.Label>เบอร์โทรศัพท์</Form.Label>
                  <Form.Control type="text" placeholder="เบอร์โทรศัพท์"
                    name='tel'
                    value={formData?.assist_technician?.phone || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="contactName">
                  <Form.Label>ชื่อช่าง</Form.Label>
                  <Form.Control type="text" placeholder="ชื่อผู้รับเหมา"
                    name='name'
                    value={`${formData?.assist_technician?.first_name} ${formData?.assist_technician?.last_name}`}
                    disabled />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Email"
                    name='email'
                    value={formData?.assist_technician?.email || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>เลขผู้เสียภาษี</Form.Label>
                  <Form.Control type="text" placeholder="เลขผู้เสียภาษี"
                    name='citizen_id'
                    value={formData?.assist_technician?.tax_id || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>ที่อยู่</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="ที่อยู่"
                    name='address'
                    value={formData?.assist_technician?.address || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6}>
                <Form.Group controlId="province" className="mb-3">
                  <Form.Label>จังหวัด</Form.Label>
                  <Form.Control type="text"
                    name='address'
                    value={formData?.assist_technician?.province_name || ""}
                    disabled>

                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="district" className="mb-3">
                  <Form.Label>เขต/ตำบล</Form.Label>
                  <Form.Control type="text"
                    name='address'
                    value={formData?.assist_technician?.district_name || ""}
                    disabled>

                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="subdistrict" className="mb-3">
                  <Form.Label>แขวง/ตำบล</Form.Label>
                  <Form.Control type="text"
                    name='address'
                    value={formData?.assist_technician?.sub_district_name || ""}
                    disabled>

                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>

        {/* Right Column */}
        <Col lg={6}>

        </Col>
      </Row>
    </Container>
  )
}

export default Respondible_tech
