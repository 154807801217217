import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Table, Button, Stack, Pagination } from 'react-bootstrap';
import Equipmentmodal from "./equipment_modal";


function Equipment({ setAsset, asset, disabled, data,EquipmentData }) {
  console.log("asset", asset)
  const [installationAssert, setInstallationAssert] = useState([]);
  console.log("EquipmentData", EquipmentData)
  const [filteredProducts, setFilteredProducts] = useState([]);
  console.log("filteredProducts", filteredProducts)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [group_name, setGroupName] = useState('');
  const [brand_name, setBrandName] = useState('');
  const [model_id, setModelId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState([]);



  const handleSearch = (group_name, brand_name, model_id) => {
    const filtered = installationAssert.filter((product) => {
      const matchesGroup = !group_name || product.brand === group_name;
      const matchesBrand = !brand_name || product.brand === brand_name;
      const matchesModel = !model_id || product.model.toLowerCase().includes(model_id.toLowerCase());
      return matchesGroup && matchesBrand && matchesModel;
    })
    console.log("filtered", filtered)
    setFilteredProducts(filtered);
    setCurrentPage(1);
  };
  const handleClearSearch = () => {
    setGroupName(''); // Reset group_name filter
    setBrandName(''); // Reset brand_name filter
    setModelId(''); // Reset model_id filter
    setFilteredProducts(installationAssert); // Reset filtered products to full data
  };

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(filteredProducts?.length / itemsPerPage);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "group_name") {
      setGroupName(value);
    } else if (name === "brand_name") {
      setBrandName(value);
    } else if (name === "model_id") {
      setModelId(value);
    }
  };

  const handleOpenModal = (data) => {
    setShowModal(true);
    setRowData(data);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setInstallationAssert(EquipmentData);
    setFilteredProducts(EquipmentData);
  }, [EquipmentData]);

  return (
    <Container fluid style={{ padding: "16px 0" }}>
      <Row className="mb-3">
        <Col>
          <Form.Label>เพิ่ม List สินค้าที่จะติดตั้ง</Form.Label>
          <Stack direction='horizontal' gap={3} className="align-items-end">
            <Form.Group>
              <Form.Select
                placeholder=""
                name="group_name"
                value={group_name}
                onChange={handleChange}
                style={{ width: "200px" }}

              >
                <option value="">เลือกกลุ่มสินค้า</option>
                {installationAssert.map((item, index) => (
                  <option key={index} value={item.brand}>
                    {item.brand}
                  </option>
                ))
                }
              </Form.Select>

            </Form.Group>

            <Form.Group>
              <Form.Select
                placeholder=""
                name="brand_name"
                value={brand_name}
                onChange={handleChange}
                style={{ width: "200px" }}
              >
                <option value="">เลือกแบรนด์สินค้า</option>
                {installationAssert.map((item, index) => (
                  <option key={index} value={item.brand}>
                    {item.brand}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group>
              <Form.Select
                placeholder="ค้นหา Model อุปกรณ์"
                name="model_id"
                value={model_id}
                onChange={handleChange}
                style={{ width: "200px" }}
              >
                <option value="">เลือกโมเดลสินค้า</option>
                {installationAssert.map((item, index) => (
                  <option key={index} value={item.model}>
                    {item.model}
                  </option>
                ))}
              </Form.Select>

            </Form.Group>

            <Button variant="primary" onClick={() => handleSearch(group_name, brand_name, model_id)} onDoubleClick={handleClearSearch}>
              ค้นหา
            </Button>
          </Stack>
        </Col>
      </Row>

      <Table responsive hover>
        <thead>
          <tr>
            <th style={{ backgroundColor: 'rgba(228, 231, 236, 1)', color: "rgba(71, 84, 103, 1)" }}>ลำดับ</th>
            <th style={{ backgroundColor: 'rgba(228, 231, 236, 1)', color: "rgba(71, 84, 103, 1)" }}>Brand</th>
            <th style={{ backgroundColor: 'rgba(228, 231, 236, 1)', color: "rgba(71, 84, 103, 1)" }}>Model สินค้า</th>
            <th style={{ backgroundColor: 'rgba(228, 231, 236, 1)', color: "rgba(71, 84, 103, 1)" }}>รหัสสินค้า</th>
            <th style={{ backgroundColor: 'rgba(228, 231, 236, 1)', color: "rgba(71, 84, 103, 1)" }}>ชื่อสินค้า</th>
            <th style={{ backgroundColor: 'rgba(228, 231, 236, 1)', color: "rgba(71, 84, 103, 1)" }}>จำนวน</th>
            <th style={{ backgroundColor: 'rgba(228, 231, 236, 1)', color: "rgba(71, 84, 103, 1)" }}></th>

          </tr>
        </thead>
        <tbody>
          {filteredProducts ? (
            filteredProducts.map((product, index) => (
              <tr key={index}>
                <td >{index + 1}</td>
                <td>{product?.brand || ''}</td>
                <td>{product?.model || ''}</td>
                <td>{product?.code || ''}</td>
                <td>{product?.name || ''}</td>
                <td>{product?.quantity || ''}</td>
                <td>
                  <div className="d-flex justify-content-evenly">
                    <div
                      href="#"
                      style={{ color: 'rgba(93, 135, 255, 1)', borderBottom: '1px solid rgba(93, 135, 255, 1)' ,cursor: 'pointer' }}
                      onClick={() => handleOpenModal(product)}
                    >
                      ดูรูปภาพ
                    </div>
                  </div>
                </td>
              </tr>
            ))

          ) : (
            <tr>
              <td colSpan="8" style={{ textAlign: 'center' }}>
                No data available
              </td>
            </tr>
          )}

        </tbody>
        <Equipmentmodal show={showModal} onHide={handleCloseModal} disabled={true} setShowModal={setShowModal} rowData={rowData} />
      </Table>
      <Row className="mt-3">
        <Col>
          <Pagination className="justify-content-end">
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Prev
            </Pagination.Prev>
            {[...Array(totalPages)].map((_, i) => (
              <Pagination.Item
                key={i}
                active={i + 1 === currentPage}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Pagination.Next>
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
}

export default Equipment;
