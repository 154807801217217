import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import PureCounter from "@srexi/purecounterjs";
import reportWebVitals from './reportWebVitals';
import './index.css'; // Import your CSS file
// Function to load an external script
const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

// Load vendor scripts in sequence or parallel
const loadVendorScripts = async () => {
  try {
    await loadScript(`${process.env.PUBLIC_URL}/assets/vendor/bootstrap/js/bootstrap.bundle.min.js`);
    await loadScript(`${process.env.PUBLIC_URL}/assets/vendor/php-email-form/validate.js`);
    await loadScript(`${process.env.PUBLIC_URL}/assets/vendor/aos/aos.js`);
    await loadScript(`${process.env.PUBLIC_URL}/assets/vendor/swiper/swiper-bundle.min.js`);
    await loadScript(`${process.env.PUBLIC_URL}/assets/vendor/glightbox/js/glightbox.min.js`);
    await loadScript(`${process.env.PUBLIC_URL}/assets/vendor/imagesloaded/imagesloaded.pkgd.min.js`);
    await loadScript(`${process.env.PUBLIC_URL}/assets/vendor/isotope-layout/isotope.pkgd.min.js`);
    await loadScript(`${process.env.PUBLIC_URL}/assets/vendor/purecounter/purecounter_vanilla.js`);
    await loadScript(`${process.env.PUBLIC_URL}/assets/js/main.js`);
  } catch (error) {
    console.error('Error loading scripts:', error);
  }
};

function Main() {
  const pure = new PureCounter();
  useEffect(() => {
    console.log("loadVendorScripts");
    loadVendorScripts();  // Load scripts when the component mounts
  }, []); // Only run on component mount

  return <App />;
}

// Create Root and Render App
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);

// If you want to start measuring performance, pass a function to log results
reportWebVitals();
