
import { Row, Col, Container } from 'react-bootstrap'
import "../../Ticket/Ticket_sys/TicketManagement/TicketPage.css"
import React from 'react';
import ReportSearch from './ReportSearch'; 
import { useTranslation } from 'react-i18next';
function TicketManagement({token}) {
  const {t}=useTranslation();


  return (
      <div className="page-wrapper" id="main-wrapper" data-layout="vertical" style={{padding:"0px" }}>
          <Container fluid style={{ width: "100%",padding:"69px 53px" ,maxWidth:"1320px" }}>
              <Row>
                  <Col lg={12}>
                      <div className="body-wrapper">
                          {/* <Header /> */}
                              <div className="Ticket-title" ><h2 >{t("report_management")}</h2></div>
                              <ReportSearch  token={token}/>
                      </div>
                  </Col>
              </Row>
          </Container>
      </div>
  );
}

export default TicketManagement;


