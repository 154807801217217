import React, { useState, useEffect } from 'react';
import "./contractor.css";
import { Stack, Modal, Form, Image } from 'react-bootstrap';

function Uploader({ imagePath, disabled, setFormData, tab, Action }) {
    const [image, setImage] = useState(imagePath || ""); // Initialize with imagePath if available
    const [filename, setFilename] = useState(image ? image.split('/').pop() : "No Selected File");
    const [show, setShow] = useState(false);
    // const [file, setFile] = useState(null); 

    useEffect(() => {
        setImage(imagePath);
    }, [imagePath]);

    const handleClose = () => setShow(false);
    const Imgpop = () => setShow(true);

    const handleFileChange = (selectedFile) => {
        const fileUrl = URL.createObjectURL(selectedFile);
        setFilename(selectedFile.name);
        setImage(fileUrl);
        // setFile(selectedFile); // Save the file object

        // Update parent form data state with the File object
        setFormData((prevState) => ({
            ...prevState,
            image: {
                ...prevState.image,
                [tab]: selectedFile, // Store the File object
            },
        }));
    };


    return (
        <Stack direction="horizontal" className="Stack-contarctor s" style={{ display: "flex", alignItems: "end" }}>
            <div className="uploader-contractor" >
                {image && (
                    <>
                        <Image
                            style={{ cursor: "pointer" }}
                            src={image}
                            alt={filename}
                            className="imgup-contractor"
                            onClick={Imgpop}
                        />
                        <Modal
                            show={show}
                            onHide={handleClose}
                            aria-labelledby="example-custom-modal-styling-title"
                        >
                            <Modal.Header closeButton >
                                <Modal.Title id="example-custom-modal-styling-title">
                                    รูปภาพลายเซ็น
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Image src={image} alt={filename} fluid />
                            </Modal.Body>
                        </Modal>
                    </>
                )}
            </div>

            <Form.Group className="mb-3" style={{ display: "flex", alignItems: "end" }}>
                <Form.Control
                    style={{ visibility: disabled ? "collapse" : "visible" }}
                    type="file"
                    accept="image/*"
                    className="input-filed-contractor"
                    disabled={disabled}
                    onChange={({ target: { files } }) => {
                        if (files && files[0]) {
                            handleFileChange(files[0]);
                        }
                    }}
                />
            </Form.Group>
        </Stack>
    );
}

export default Uploader;
