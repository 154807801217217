// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-tabs .nav-link {
    font-size: 14px;
    font-weight: 500;
    color: #495057;
    background-color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #dee2e6;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-tabs .nav-link.active {
    background-color: #EDF2FF;
    color: black;
    border-color: #e9ecef;
}

.nav-tabs .nav-link:hover {
    background-color: #e9ecef;
    color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/components/Page/Total/TotalSystem/Modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,uDAAuD;AAC3D;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".nav-tabs .nav-link {\r\n    font-size: 14px;\r\n    font-weight: 500;\r\n    color: #495057;\r\n    background-color: white;\r\n    padding: 10px 20px;\r\n    border-radius: 5px;\r\n    border: 1px solid #dee2e6;\r\n    transition: background-color 0.3s ease, color 0.3s ease;\r\n}\r\n\r\n.nav-tabs .nav-link.active {\r\n    background-color: #EDF2FF;\r\n    color: black;\r\n    border-color: #e9ecef;\r\n}\r\n\r\n.nav-tabs .nav-link:hover {\r\n    background-color: #e9ecef;\r\n    color: #333;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
