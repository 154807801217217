import React, { useState, useEffect } from 'react';
import { Form, Button, Stack, Modal } from 'react-bootstrap';
import Bgcolore from "../../../../../assets/img/_Input field base.svg"



function ImageUploader({ setProductData, singleImage = false, ProductData, disabled, show }) {
  console.log("ProductData", ProductData)
  const [selectedImages, setSelectedImages] = useState([]);
  console.log("selectedImages", selectedImages)
  const [fileNames, setFileNames] = useState([]);
  const [delImageArray, setDelImageArray] = useState([]); // For deleted image auto_ids
  const [insertImages, setInsertImages] = useState([]); // For new images to insert
  const [nullimage, setNullimage] = useState('ยังไม่ได้เลือกไฟล์');
  const [showModal, setShowModal] = useState(false);
  const [Index, setIndex] = useState(null);
  const [MockupImages, setMockupImages] = useState([Bgcolore, Bgcolore, Bgcolore, Bgcolore]);

  const ClearState = () => {
    setSelectedImages([]);
    setFileNames([]);
    setDelImageArray([]);
    setInsertImages([]);
    setNullimage('ยังไม่ได้เลือกไฟล์');
    setIndex(null);
  }

  useEffect(() => {
    if (ProductData?.product_images?.length > 0) {
      setSelectedImages(ProductData.product_images);
    }
  }, [ProductData])

  const ShowImageModal = ({ showModal, setShowModal, imageUrl }) => {
    console.log("imageUrl", imageUrl)
    const [ModalImage, setModalImage] = useState("")

    useEffect(() => {
      if (imageUrl) {
        setModalImage(imageUrl)
      }
    }, [imageUrl])

    return (
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        size='lg'
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>รูปภาพที่อัพโหลด</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={ModalImage} alt='Bgcolore' style={{ width: '100%', height: '100%', maxHeight: '400px', objectFit: 'contain' }} />
          </div>
        </Modal.Body>
      </Modal>

    )
  };

  const selectImages = (event) => {
    const files = Array.from(event.target.files);

    if (singleImage) {
      const newImage = files[0];
      setSelectedImages([newImage]);
      setFileNames([newImage.name]);

      setInsertImages([newImage]);
      setProductData((prevState) => ({
        ...prevState,
        pic: [newImage], // Update with the new image
      }));
    } else {
      setSelectedImages((prevImages) => [...prevImages, ...files]);
      setFileNames((prevNames) => [...prevNames, ...files.map((file) => file.name)]);

      setInsertImages((prevInsert) => [...prevInsert, ...files]); // Add to insert list
      setProductData((prevState) => ({
        ...prevState,
        pic: [...(prevState.pic || []), ...files], // Update with new images
      }));
    }
  };

  const removeImage = (indexToRemove) => {
    const imageToRemove = selectedImages[indexToRemove];

    if (imageToRemove.auto_id) {
      console.log('Removing image with auto_id:', imageToRemove.auto_id);

      // Update delImageArray and ProductData state correctly
      setDelImageArray((prev) => {
        const updatedArray = [...prev, imageToRemove.auto_id];
        console.log('Updated del_image_array:', updatedArray);

        // Ensure del_image_array is updated within ProductData
        setProductData((prevState) => ({
          ...prevState,
          del_image_array: updatedArray,
        }));

        return updatedArray;
      });
    }

    // Remove the selected image from the list
    const updatedImages = selectedImages.filter((_, index) => index !== indexToRemove);
    const updatedFileNames = fileNames.filter((_, index) => index !== indexToRemove);

    setSelectedImages(updatedImages);
    setFileNames(updatedFileNames);

    // Update ProductData with the remaining images
    setProductData((prevState) => ({
      ...prevState,
      pic: updatedImages,
    }));
  };

  const handleButtonClick = () => {
    document.getElementById('fileInput').click(); // Programmatically click the hidden input
  };

  const handleModalShow = (index) => {
    setShowModal(true);
    setIndex(index);
  };

  useEffect(() => {
    if (ProductData?.pic) {
      setSelectedImages(ProductData.pic); // Load existing images
    } else if (ProductData?.Pictures) {
      const Images = ProductData.Pictures.map((item) => ({
        PicUrl: item.Pic
      }))
      console.log("Images", Images)
      setSelectedImages(Images);
    }
  }, [ProductData]);

  useEffect(() => {
    if (show === false) {
      console.log("ClearState", show)
      ClearState();
    }
  }, [show]);

  return (
    <Stack direction="row" gap={0}>
      <Form.Group controlId="formFile" className="mb-3" style={{ position: 'relative' }}>
        <Form.Control
          id="fileInput"
          type="file"
          accept="image/*"
          multiple={!singleImage}
          onChange={selectImages}
          onClick={(e) => (e.target.value = '')} // Reset input for re-upload
          style={{
            display: 'none', // Hide the input
          }}
        />
        <Stack direction="horizontal" gap={2}>
          <Button onClick={handleButtonClick}
            disabled={disabled}
            style={{
              maxWidth: '300px',
              height: '36px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(237, 242, 255, 1)',
              color: '#333',

            }}>
            เลือกไฟล์
          </Button>
          <div
            style={{
              visibility: disabled ? 'hidden' : 'visible',
              whiteSpace: 'nowrap',
              maxWidth: '300px',
              height: '36px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '10px',
              paddingRight: '10px', // Added padding for better spacing
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '300px',
                color: '#333',
                visibility: disabled ? 'hidden' : 'visible',
              }}
            >
              {fileNames.length === 0 ? nullimage : fileNames.join(', ')}
            </div>
          </div>
        </Stack>
      </Form.Group>
      <div style={{ display: 'flex', flexWrap: 'wrap' }} className="mb-3">
        {
          (disabled ? selectedImages : MockupImages) &&
          (disabled ? selectedImages : MockupImages).length > 0 &&
          (disabled ? selectedImages : MockupImages).map((image, index) => (

            <div key={index} style={{ position: 'relative', marginRight: '10px',marginBottom: '10px' }}>
              <img
                onClick={() => handleModalShow(index)}
                src={disabled ? image : image}
                alt={`Uploaded Preview ${index + 1}`}
                style={{
                  width: '100px',
                  height: '100px',
                  objectFit: 'cover',
                  borderRadius: '3px',
                  cursor: "pointer"
                }}
              />
              {showModal && index === Index && (
                <ShowImageModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  imageUrl={disabled ? image : image}
                />
              )}
            </div>
          ))
        }
      </div>
    </Stack>
  );
}

export default ImageUploader;
