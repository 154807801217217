import React, { useState, useEffect } from 'react';
import { Container, Table } from 'react-bootstrap';
import Cookies from 'js-cookie';
import axios from 'axios';
import RepairModal from './RepairModal';
import Spinner from 'react-bootstrap/Spinner';

function RepairHistory(
  {
    data,
    disabled
  }
) {
  console.log("data", data)
  const [RowsData, setRowsData] = useState([])
  console.log("RowsData", RowsData)
  const [RepairHistory, setRepairHistory] = useState([])
  const [historyshow, sethistoryshow] = useState(false)
  const columns = [
    { header: 'ลำดับ', key: 'index' },
    { header: 'รหัสใบงานติดตั้ง', key: 'doc_number', },
    { header: 'วันที่ติดตั้ง', key: 'repair_date', },
    { header: 'ลิ้งดูใบงาน', key: 'actions', },
  ];
  const paginationModel = { page: 0, pageSize: 10 };

  const [loading, setLoading] = useState(false);

  const FetchRepairHistory = async () => {
    setLoading(true);
    try {
      const token = Cookies.get('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/customer/asset-installations/repair-history `, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.data;
      setRepairHistory(data);
    } catch (error) {
      console.error('Error fetching repair history:', error);
    } finally {
      await new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
        setLoading(false);
      })

    }
  }

  const handleshowhistory = (data) => {
    sethistoryshow(true);
    setRowsData(data);
  }

  const handleChangeDate = (dateString) => {
    if (!dateString) return ""; // Handle empty or undefined date

    // Parse the dateString into a JavaScript Date object
    const date = new Date(dateString);

    // Format date to Thai format using `toLocaleDateString`
    const formattedDate = date.toLocaleDateString("th-TH", {
      day: "numeric",
      month: "long",
      year: "numeric"
    });

    return `${formattedDate}`;
  };

  useEffect(() => {
    FetchRepairHistory();
  }, [])


  return (
    <Container fluid className="contractor-container">
      <Table hover responsive size='md' className="table-border" style={{ padding: "0px", margin: "0px" }}>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <thead>
              <tr>
                {columns.map(col => (
                  <th key={col.key} className={`work-table-header custom-background ${col.Name}`}>
                    {col.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {RepairHistory
                ?.slice(
                  paginationModel.page * paginationModel.pageSize,
                  (paginationModel.page + 1) * paginationModel.pageSize
                )
                ?.map((row, rowIndex) => (
                  <tr key={row.index} className="work-table-row">
                    {columns.map(col => (
                      <td key={col.key} className={`work-table-cell ${col.Name}`}>
                        {col.key === 'checkbox' ? (
                          <input
                            type="checkbox"


                          />
                        ) : col.key === 'actions' ? (
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div
                              onClick={() => handleshowhistory(row)} // Pass function reference
                              style={{
                                color: 'rgba(93, 135, 255, 1)',
                                borderBottom: '1px solid rgba(93, 135, 255, 1)',
                                cursor: 'pointer',
                                width: 'fit-content'
                              }}
                            >
                              ดูรายละเอียด
                            </div>
                          </div>
                        ) : col.key === "repair_date" ? (
                          handleChangeDate(row[col.key])
                        ) : col.key === 'index' ? (
                          rowIndex + 1
                        ) : (
                          row[col.key]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </>
        )}
        <RepairModal
          show={historyshow}
          rowsData={RowsData}
          sethistoryshow={sethistoryshow}
          disabled={disabled}
          centered
          Assetdata={data}
          Action={"repair"}
        />
      </Table>

    </Container>
  )
}

export default RepairHistory
