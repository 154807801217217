import React, { useState, useEffect } from 'react';
import { Form, Button, Stack } from 'react-bootstrap';

function ImageUploader({ setData, singleImage = false, data, disabled }) {
  console.log(disabled)
  const [selectedImages, setSelectedImages] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [delImageArray, setDelImageArray] = useState([]); // For deleted image auto_ids
  const [insertImages, setInsertImages] = useState([]); // For new images to insert
  // const [disabledInput, setDisabledInput] = useState(false);
  // Populate existing images from data when component loads
  useEffect(() => {
    if (data?.pic) {
      setSelectedImages(data.pic); // Load existing images
    }
  }, [data]);

  const selectImages = (event) => {
    const files = Array.from(event.target.files);

    if (singleImage) {
      const newImage = files[0];
      setSelectedImages([newImage]);
      setFileNames([newImage.name]);

      setInsertImages([newImage]);
      setData((prevState) => ({
        ...prevState,
        pic: [newImage], // Update with the new image
      }));
    } else {
      setSelectedImages((prevImages) => [...prevImages, ...files]);
      setFileNames((prevNames) => [...prevNames, ...files.map((file) => file.name)]);

      setInsertImages((prevInsert) => [...prevInsert, ...files]); // Add to insert list
      setData((prevState) => ({
        ...prevState,
        pic: [...(prevState.pic || []), ...files], // Update with new images
      }));
    }
  };

  const removeImage = (indexToRemove) => {
    const imageToRemove = selectedImages[indexToRemove];

    if (imageToRemove.auto_id) {
      console.log('Removing image with auto_id:', imageToRemove.auto_id);

      // Update delImageArray and data state correctly
      setDelImageArray((prev) => {
        const updatedArray = [...prev, imageToRemove.auto_id];
        console.log('Updated del_image_array:', updatedArray);

        // Ensure del_image_array is updated within data
        setData((prevState) => ({
          ...prevState,
          del_image_array: updatedArray,
        }));

        return updatedArray;
      });
    }

    // Remove the selected image from the list
    const updatedImages = selectedImages.filter((_, index) => index !== indexToRemove);
    const updatedFileNames = fileNames.filter((_, index) => index !== indexToRemove);

    setSelectedImages(updatedImages);
    setFileNames(updatedFileNames);

    // Update data with the remaining images
    setData((prevState) => ({
      ...prevState,
      pic: updatedImages,
    }));

  

    // if (data.pic === null) {
    //   console.log("Setting disabledInput to true");
    //   setDisabledInput(false);
    // }else{
    //   console.log("Setting disabledInput to false");
    // }
  };

  return (
    <Stack direction="row" gap={0}>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>รูปสินค้า</Form.Label>
        <Form.Control
          style={{ visibility : disabled ? 'hidden' : 'visible' }}
          disabled={disabled}
          type="file"
          accept="image/*"
          multiple={!singleImage}
          onChange={selectImages}
          onClick={(e) => (e.target.value = '')} // Reset input for re-upload
        />
      </Form.Group>

      <div style={{ display: 'flex', flexWrap: 'wrap' }} className="mb-3">
        {selectedImages.map((image, index) => (
          <div key={index} style={{ position: 'relative', marginRight: '10px' }}>
            <img
              src={image.Pic ? image.Pic : URL.createObjectURL(image)}
              alt={`Upload Preview ${index + 1}`}
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'cover',
                borderRadius: '3px',
              }}
            />
            <Button
              variant="danger"
              size="sm"
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                borderRadius: '50%',
                padding: '0 5px',
                fontSize: '12px',
              }}
              onClick={() => removeImage(index)}
            >
              X
            </Button>
          </div>
        ))}
      </div>
    </Stack>
  );
}

export default ImageUploader;
