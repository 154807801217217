import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DownLoadicon from "../../../../assets/img/sunpeakicon/dowloadicon.svg"
import "../../Ticket/Ticket_sys/TicketManagement/checklistsearch.css"
import { useTranslation } from 'react-i18next';

function ReportSearch() {
    const [fileName, setFileName] = useState("");
    console.log("fileName", fileName)
    const { t } = useTranslation();
    const handleDownload = (data) => {
        const fileToDownload = data ? data : "";
        const fileUrl = `${process.env.PUBLIC_URL}/Reports/${fileToDownload}.xlsx`;

        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = fileToDownload;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFileChange = (event) => {
        setFileName(event.target.value);
    };

    return (
        <Container fluid className="checklistsearch-container container-style" style={{
            padding: '16px 24px',
            borderRadius: '12px',
            border: '1px solid rgba(230, 233, 244, 1)',
            boxShadow: '0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.2)',
            marginBottom: "20px",
            maxWidth: "1320px"
        }}
        >
            <Row>
                <Col>
                    <Form.Label className="search-header">{t("choseload")}</Form.Label>
                </Col>
            </Row>
            <Row style={{ marginBottom: "8px" }}>
                <Col style={{ maxWidth: "300px" }}>
                    <Form.Group className='form-group' style={{ maxWidth: "300px" }}>
                        <Form.Label  >ประเภทรายงาน</Form.Label>
                        <Form.Select
                            value={fileName}
                            aria-label="Default select example"
                            className="input-wrapper"
                            onChange={handleFileChange}
                        >
                            <option value="" disabled>เลือกประเภทรายงาน</option>
                            <option value="รายงานประจำวัน">รายงานแจ้งซ่อมประจำวัน</option>
                            <option value="รายงานประจำเดือน">รายงานแจ้งซ่อมประจำเดือน</option>
                            <option value="รายงานสรุปประจำเดือน">รายงานสรุปแจ้งซ่อมประจำเดือน</option>
                            <option value="รายงานประจำปี">รายงานสรุปแจ้งซ่อมประจำปี</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

            <Row style={{ marginBottom: "8px" }}>
                <Col style={{ maxWidth: "300px" }}>
                    <Form.Group className='form-group' style={{ maxWidth: "300px" }}>
                        <Form.Label >{t("startdate")}</Form.Label>
                        <Form.Control
                            className="input-wrapper"
                            type='date'
                        />
                    </Form.Group>
                </Col>
                <Col style={{ maxWidth: "300px" }}>
                    <Form.Group className='form-group' style={{ maxWidth: "300px" }}>
                        <Form.Label >{t("enddate")}</Form.Label>
                        <Form.Control
                            className="input-wrapper"
                            type='date'

                        />
                    </Form.Group>
                </Col>
                <Col style={{ display: "flex", justifyContent: "start", alignItems: "end", maxWidth: "300px" }} >

                    <Form.Group className='form-group' style={{ maxWidth: "300px" }}>
                        <Button
                             onClick={() => handleDownload(fileName)}
                            className="search-button"
                            style={{ backgroundColor: "rgba(93, 135, 255, 1)", color: "rgba(255, 255, 255, 1)" }}
                        >
                            <img src={DownLoadicon} alt="searchicon" />
                            <span>{t("download")}</span>

                        </Button>
                    </Form.Group>

                </Col>
            </Row>
        </Container>
    )
}

export default ReportSearch
