import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CourtLoGo from '../../assets/img/sunpeaktechlogo/cojlogo.png'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { Stack, DropdownButton, Dropdown } from 'react-bootstrap';
import Usericon from '../../assets/img/sunpeakicon/usericon.svg'
import Switch from "../SwitchLanguage/Switch"


function Header({ isNavOpen, setIsNavOpen }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [user, setUser] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
        const token = Cookies.get('token');
        if (!token) {
            // ถ้าไม่มี token ให้เปลี่ยนเส้นทางไปยังหน้า Login
            // navigate('/login');
        } else {
            setUser(true);
        }
    }, [navigate]);

    const toggleMobileNav = () => {
        setIsNavOpen((prev) => !prev); // Toggle the nav state
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev); // Toggle the dropdown state
    };

    const logout = () => {
      Swal.fire({
          title: 'คุณแน่ใจหรือไม่?',
          text: "คุณต้องการออกจากระบบ",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'rgba(93, 135, 255, 1)',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ใช่, ออกจากระบบ',
          cancelButtonText: 'ยกเลิก'
      }).then((result) => {
          // Check if the user confirmed the logout action
          if (result.isConfirmed) {
              // Remove cookies
              Cookies.remove('token');
              Cookies.remove('username');
              // Set user state to false
              setUser(false);
              navigate('/login');
          }
      });
  };
  


    return (
        <header id="header" className="header d-flex align-items-center fixed-top">
        <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-center align-items-lg-center">
          <a href={user ? '/home' : '/login'} className="logo d-flex align-items-center me-auto me-lg-0" id = "logo">
            <img src={CourtLoGo} alt="logo" style={{ margin: '0px', width: 'auto', maxHeight: '60px' }} />
          </a>
  
          <nav id="navmenu" className={`navmenu ${isNavOpen ? 'mobile-nav-active' : ''}`}>
            <ul>
              <li>
                <Link to={user ? '/home' : '/login'}>{t('home')}</Link>
              </li>
              <li className="dropdown">
                <Link to="#" className={`${isDropdownOpen ? 'active' : ''}`}>
                  <span>{t('services')}</span>
                  <i className="bi bi-chevron-down toggle-dropdown" onClick={toggleDropdown}></i>
                </Link>
                <ul className={`${isDropdownOpen ? 'dropdown-active' : ''}`}>
                  <li onClick={toggleMobileNav}>
                    <Link to={user ? '/assetinstallation' : '/login'}>
                      <span>{t('asset_management')}</span>
                    </Link>
                  </li>
                  <li onClick={toggleMobileNav}>
                    <Link to={user ? '/ticket' : '/login'}>
                      <span>{t('ticketManagement')}</span>
                    </Link>
                  </li>
                  <li onClick={toggleMobileNav}>
                    <Link to={user ? '/alltaskmanagement' : '/login'}>{t('total_management')}</Link>
                  </li>
                  <li onClick={toggleMobileNav}>
                    <Link to={user ? '/report' : '/login'}>{t('ticketReport')}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="#">{t('aboutUs')}</Link>
              </li>
            </ul>
            <i className={`mobile-nav-toggle  d-xl-none ${isNavOpen ? 'bi bi-x' : 'bi bi-list'}`} onClick={toggleMobileNav}></i>
          </nav>
  
          {user ? (
            <div className="header-user">
              <Stack direction="horizontal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', padding: '0 20px' }}>
                <img src={Usericon} alt="usericon" />
                <div style={{ color: '#ffffff', display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                  <DropdownButton title={Cookies.get('username')} style={{ border: 'none', color: '#ffffff' }} variant="outline-light">
                    <Dropdown.Item
                      onClick={logout}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      style={{
                        border: 'none',
                        backgroundColor: isHovered ? 'rgb(218, 0, 0)' : '#ffffff',
                        color: 'rgb(0, 0, 0)',
                      }}
                    >
                      {t('logout')}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
                <div className="Switch-language" style={{ color: '#ffffff', display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                  <Switch />
                </div>
              </Stack>
            </div>
          ) : (
            <Stack direction="horizontal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', padding: '0 20px' }}>
              <Link className="btn-getstarted" to="/register">{t('register')}</Link>
              <Link className="btn-getstartedred" to="/login">{t('login')}</Link>
              <div className="Switch-language" style={{ color: '#ffffff', display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <Switch />
              </div>
            </Stack>
          )}
        </div>
      </header>
    )
}

export default Header
