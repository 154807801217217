
import { Row, Col, Container } from 'react-bootstrap'
import TicketSearch from './TicketSearch';
import TicketTable from "./TicketTable"
import "./TicketPage.css"
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
function TicketManagement({ token }) {

    const { t } = useTranslation();
    const [ticketData, setTicketData] = useState([]);
    console.log("Fetching ticket data...", ticketData);
    console.log("token", token)
    const [loading, setLoading] = useState(true);
    const [showTicketData, setShowTicketData] = useState([]);

    const fetchTickets = async (params = {}) => {
        try {
            setLoading(true);
            if (params.status === "") {
                delete params.status;
            }
            const queryString = new URLSearchParams(params).toString();

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/customer/tickets/search?${queryString}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            setShowTicketData(response.data.data)
            setTicketData(response.data.data);
        } catch (error) {
            console.error("Failed to fetch tickets:", error);
        } finally {
            await new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
                setLoading(false);
            })
        }
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    // const handleSearch = (searchParams) => {
    //     fetchTickets(searchParams);  // Fetch data based on search input
    // }

    const handleSearch = (searchParams) => {
        const filteredData = ticketData.filter((item) =>
            (!searchParams.contractor_id ||
                item.contractor_id.includes(searchParams.contractor_id) ||
                item.contractor_name.includes(searchParams.contractor_id)) &&
            (!searchParams.customer_id ||
                item.customer_id.includes(searchParams.customer_id) ||
                item.customer_name.includes(searchParams.customer_id)) &&
            (!searchParams.ticket_id ||
                item.Ticket_ID.includes(searchParams.ticket_id)) &&
            (!searchParams.ticket_date ||
                new Date(item.TicketDate).toISOString().slice(0, 10) === searchParams.ticket_date) &&
            (!searchParams.status ||
                item.Status.toString() === searchParams.status)
        );
        setShowTicketData(filteredData)
    }

    return (
        <div className="page-wrapper" id="main-wrapper" data-layout="vertical" style={{ padding: "0px" }}>
            <Container fluid style={{ width: "100%", padding: "69px 53px", maxWidth: "1320px" }}>
                <Row>
                    <Col lg={12}>
                        <div className="body-wrapper">
                            {/* <Header /> */}
                            <div className="Ticket-title" ><h2 >{t('ticket_management')}</h2></div>
                            <TicketSearch onSearch={handleSearch} token={token} />
                            <TicketTable ticketData={showTicketData} fetchData={fetchTickets} token={token}  loading={loading}/>
                        </div>
                    </Col>
                    {/* <Col lg={0}>
                      <Sidebar />
                  </Col> */}
                </Row>
            </Container>
        </div>
    );
}

export default TicketManagement;


