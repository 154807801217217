import React, {useEffect } from 'react';

import {  Stack, Container } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import TotalPage from './TotalSystem/TotalPage';
function Total() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease-in-out', // Animation easing
    });
  }, []);

  const { t } = useTranslation();

  return (
    <Container fluid className='main mt-5' style={{ padding: "0px" }}>
      <Stack direction='vertical' >
        <div className="page-title" data-aos="fade">
          <div className="heading">
            <div className="container">
              <div className="row d-flex justify-content-center text-center">
                <div className="col-lg-8">
                  <h1>{t('total_management')}</h1>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadcrumbs">
            <div className="container">
              <ol>
                <li><a href="/home">{t('home')}</a></li>
                {/* <li>Service List</li> */}
                <li className='assetinstall'>{t('total_management')}</li>
              </ol>
            </div>
          </nav>
        </div>
        <div  data-aos="fade"><TotalPage /> </div>
      </Stack>
    </Container>
  )
}

export default Total
