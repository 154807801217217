
import { Row, Col, Container } from 'react-bootstrap'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import AssetInstallSearch from './AssetInstallSearch';
import AssetInstallTable from './AssetInstallTable';
import "../../Ticket/Ticket_sys/TicketManagement/TicketPage.css"
import Cookies from 'js-cookie';
function AssetInstallPage() {
  const { t } = useTranslation();
  const [assetInstall, setAssetInstall] = useState([]);
  const [backupAssetInstall, setBackupAssetInstall] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get( `${process.env.REACT_APP_API_URL}/customer/asset-installations/search `,
        { headers: { 'Authorization': `Bearer ${Cookies.get('token')}` } }
      );
      setAssetInstall(response.data);
      console.log(response.data);
      setBackupAssetInstall(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      await new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
        setLoading(false);
      })
    }
  }


  useEffect(() => {
    fetchData();
  }, []);


  const handleSearch = (searchParams) => {
    console.log("searchParams", searchParams);

    const filteredData = backupAssetInstall.filter((item) =>
      (!searchParams.contractor_id ||
        item.contractor_id.includes(searchParams.contractor_id)) &&
      (!searchParams.brand_name ||
        item.brand_name.includes(searchParams.brand_name)) &&
      (!searchParams.asset_model_code ||
        item.asset_model_code.includes(searchParams.asset_model_code)) &&
      (!searchParams.asset_model_name ||
        item.asset_model_name.includes(searchParams.asset_model_name)) &&
      (!searchParams.group_name ||
        item.group_name.includes(searchParams.group_name)) &&
      (!searchParams.status ||
        item.status.toString().includes(searchParams.status)) &&
      (!searchParams.warranty_status ||
        item.warranty_status.toString().includes(searchParams.warranty_status)) &&
      (!searchParams.installation_date ||
        new Date(item.installation_date).toISOString().slice(0, 10) === searchParams.installation_date)
    );

    console.log("filteredData", filteredData);
    setAssetInstall(filteredData);
  };



  return (
    <div className="page-wrapper" id="main-wrapper" data-layout="vertical" style={{ padding: "0px" }}>
      <Container fluid style={{ width: "100%", padding: "69px 53px", maxWidth: "1320px" }}>
        <Row>
          <Col lg={12}>
            <div className="body-wrapper">
              {/* <Header /> */}
              <div className="Ticket-title" ><h2 >{t('asset_management')}</h2></div>
              <AssetInstallSearch onSearch={handleSearch} />
              <AssetInstallTable assetInstall={assetInstall} loading={loading} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AssetInstallPage
