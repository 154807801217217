import React from 'react'

function Preload() {
  return (
    <div id="preloader">
    </div>
  )
}

export default Preload
