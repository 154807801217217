import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Button, Row, Stack, Col, Container, Form, InputGroup } from 'react-bootstrap';
import Cookies from 'js-cookie';
function Regis({
  province
}) {
  const token = Cookies.get('token');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileLink, setFileLink] = useState('');

  const navigate = useNavigate();

  const [province_id, setProvince] = useState([]);
  const [district_id, setDistrictID] = useState([])
  const [sub_district_id, setSubDistrictID] = useState([])
  const [district, setDistrict] = useState([]);
  const [subdistrict, setSubdistrict] = useState([]);

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [tel, setPhonenumber] = useState("")
  const [name, setCompanyname] = useState("")
  const [tax_id, setTax_id] = useState("")
  const [address, setAddress] = useState("")
  const [description, setDescription] = useState("")


  const ClearState = () => {
    setUsername("")
    setPassword("")
    setEmail("")
    setPhonenumber("")
    setCompanyname("")
    setTax_id("")
    setAddress("")
    setDescription("")
    setProvince([])
    setDistrictID([])
    setSubDistrictID([])
  }


  const handleSelectChange = (event) => {
    const { name, value } = event.target
    if (name === "province_id") {
      setProvince(value);
    } else if (name === "district_id") {
      setDistrictID(value);
    } else if (name === "sub_district_id") {
      setSubDistrictID(value);
    }
  };

  const handleformchange = (event) => {
    const { name, value } = event.target;

    // Create a mapping of field names to their corresponding setter functions
    const fieldSetters = {
      username: setUsername,
      password: setPassword,
      email: setEmail,
      tel: setPhonenumber,
      name: setCompanyname,
      tax_id: setTax_id,
      address: setAddress,
      description: setDescription,
    };

    // Call the appropriate setter if the field name exists in the mapping
    if (fieldSetters[name]) {
      fieldSetters[name](value);
    }
  };





  const handleRemoveImage = () => {
    setFileLink(null); // Clear the image when "X" is clicked
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      username,
      password,
      email,
      tel,
      name,
      tax_id,
      address,
      description,
      province_id: parseInt(province_id, 10), // Ensure provinceId is an integer
      district_id: parseInt(district_id, 10), // Ensure districtId is an integer
      sub_district_id: parseInt(sub_district_id, 10)
    };

    try {
      // Validate password before sending the request

      if (username.length < 5 || !/^[A-Za-z]+$/.test(username)) {
        Swal.fire({
          icon: 'warning',
          title: 'Invalid Username',
          text: 'Username must be at least 5 characters long and contain only English letters.',
        });
        return;
      }
      if (password.length < 10 || password.length > 16 || !/^[A-Za-z0-9]+$/.test(password)) {
        Swal.fire({
          icon: 'warning',
          title: 'Invalid Password',
          text: 'Password must be 10-16 characters long, contain only English letters and numbers.',
        });
        return;
      }

      // Create FormData object for file and other data
      const dataToSend = new FormData();
      dataToSend.append('customer', JSON.stringify(formData)); // Add customer details as JSON string

      if (selectedFile) {
        dataToSend.append('logo_pic', selectedFile); // Add logo picture
      }

      // Axios POST request
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/customers/register`, dataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`, // Include token in the Authorization header
        },
      });

      // If successful, show success alert
      Swal.fire({
        icon: 'success',
        title: 'Registration Successful',
        text: `Welcome ${formData.Username}! Your registration was successful.`,
        timer: 1500,
        showConfirmButton: false,
      }).then(() => {
        ClearState();
        navigate('/login');
      })

    } catch (error) {
      // Handle error and show SweetAlert error message
      const errorMessage = error.response?.data?.error || 'An unknown error occurred during registration.';
      Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: errorMessage,
      }).then(() => {
        ClearState();
      })
    }
  };


  // Handler for file change (example)
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      setFileLink(URL.createObjectURL(file));
    }
  };




  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/locations/districts?province_id=${province_id}`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        const data = response.data;
        setDistrict(data);



      } catch (error) {
        console.error("Failed to fetch tickets:", error);
      }
    };
    fetchData();
  }, [province_id]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const subdistrictData = await axios.get(`${process.env.REACT_APP_API_URL}/locations/sub-districts?district_id=${district_id}`, {
          headers: { 'Authorization': `Bearer ${token}` },
        })

        const subdata = subdistrictData.data
        setSubdistrict(subdata)
      } catch (error) {
        console.error("Failed to fetch tickets:", error);
      }
    };
    fetchData();
  }, [district_id]);



  // {
  //   "username": "customer123",
  //     "password": "securePassword123!",
  //       "name": "Example Company Ltd.",
  //         "tax_id": "1234567890123",
  //           "description": "A leading technology solutions provider",
  //             "address": "123 Tech Street, Innovation District",
  //               "sub_district_id": 1001,
  //                 "district_id": 1,
  //                   "province_id": 1,
  //                     "post_code": "10110",
  //                       "tel": "+66123456789",
  //                         "lat_long": "13.7563,100.5018",
  //                           "remark": "Preferred communication via email",
  //                             "contact_name_1": "John Doe",
  //                               "position_1": "IT Manager",
  //                                 "contact_tel_1": "+66987654321",
  //                                   "contact_name_2": "Jane Smith",
  //                                     "position_2": "Procurement Officer",
  //                                       "contact_tel_2": "+66876543210",
  //                                         "email": "contact@examplecompany.com"
  // }



  return (
    <Container fluid className='main mt-5' style={{ padding: "0px" }}>
      <Stack direction='vertical' >
        <div className="page-title" data-aos="fade">
          <div className="heading">
            <div className="container">
              <div className="row d-flex justify-content-center text-center">
                <div className="col-lg-8">
                  <h1>ลงทะเบียน</h1>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadcrumbs">
            <div className="container">
              <ol>
                <li><a href="/home">หน้าหลัก</a></li>
                <li className="current">ลงทะเบียน</li>
              </ol>
            </div>
          </nav>
        </div>
        <div className="register-container" data-aos="fade">
          <Form onSubmit={handleSubmit}>
            {/* First Row */}
            <Row>
              {/* <Col md={6}>
                <Form.Group controlId="customerCode" className="mb-3" >
                  <Form.Label>รหัสลูกค้า</Form.Label>
                  <Form.Control placeholder="กรอกรหัสลูกค้า" name='id' value={customerCode} onChange={handleformchange} />
                </Form.Group>
              </Col> */}
              <Col md={6}>
                <Form.Group controlId="๊UserName" className="mb-3" >
                  <Form.Label>ชื่อผู้ใช้</Form.Label>
                  <Form.Control type="text" placeholder="สร้างชื่อผู้ใช้งาน" name='username' value={username} onChange={handleformchange} />
                </Form.Group>
              </Col>


              {/* "error": "Invalid password: must be 10-16 characters long, contain English letters and numbers" */}

              <Col md={6}>
                <Form.Group controlId="Password" className="mb-3" >
                  <Form.Label>รหัสผ่าน</Form.Label>
                  <Form.Control
                    type="password" // Change the input type to password
                    placeholder="สร้างรหัสผ่านผู้ใช้งาน"
                    name="password"
                    value={password}
                    onChange={handleformchange}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Second Row */}
            <Row>
              {/* <Col md={6}>
                <Form.Group controlId="companyName" className="mb-3" >
                  <Form.Label>ชื่อบริษัทผู้รับเหมา</Form.Label>
                  <Form.Control type="text" placeholder="กรอกชื่อบริษัท" name='name' value={name} onChange={handleformchange} />
                </Form.Group>
              </Col> */}
              <Col md={12}>
                <Form.Group controlId="phone" className="mb-3">
                  <Form.Label>เบอร์โทรศัพท์</Form.Label>
                  <Form.Control type="tel" placeholder="กรอกเบอร์โทรศัพท์" name='tel' value={tel} onChange={handleformchange} />
                </Form.Group>
              </Col>
            </Row>

            {/* Email Row */}
            <Row>
              <Col md={6}>
                <Form.Group controlId="taxId" className="mb-3">
                  <Form.Label>เลขประจำตัวผู้เสียภาษี</Form.Label>
                  <Form.Control placeholder="กรอกหมายเลขบัตรประชาชน" name='tax_id' value={tax_id} onChange={handleformchange} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="email" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="กรอกอีเมล" name='email' value={email} onChange={handleformchange} />
                </Form.Group>
              </Col>
            </Row>

            {/* Address Row */}
            <Row>
              <Col md={12}>
                <Form.Group controlId="address" className="mb-3">
                  <Form.Label>ที่อยู่</Form.Label>
                  <Form.Control as="textarea" rows={2} placeholder="กรอกที่อยู่" name='address' value={address} onChange={handleformchange} />
                </Form.Group>
              </Col>
            </Row>

            {/* Description Row */}
            <Row>
              <Col md={12}>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>รายละเอียด</Form.Label>
                  <Form.Control as="textarea" rows={2} placeholder="กรอกรายละเอียด" name='description' value={description} onChange={handleformchange} />
                </Form.Group>
              </Col>
            </Row>

            {/* File Upload Row */}
            <Row className="align-items-center">
              <Col md={6}>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>โลโก้</Form.Label>
                  <InputGroup>
                    <Form.Control type="file" onChange={handleFileChange} />
                    {selectedFile && <Form.Text>{selectedFile.name}</Form.Text>}
                  </InputGroup>
                  {fileLink && (
                    <Form.Text className="text-muted">
                      Link: <a href={fileLink} target="_blank" rel="noopener noreferrer">{fileLink}</a>
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <div className="image-upload-container">
                  {fileLink && (
                    <div className="image-preview-container">
                      <img src={fileLink} alt="Logo Preview" className="logo-preview" />
                      <button className="remove-image-btn" onClick={handleRemoveImage}>
                        &times;
                      </button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            {/* Province and District */}
            <Row>
              <Col md={6}>
                <Form.Group controlId="province_id" className="mb-3">
                  <Form.Label>จังหวัด</Form.Label>
                  <Form.Select
                    name='province_id'
                    value={province_id}
                    onChange={handleSelectChange} >
                    <option value="">เลือกจังหวัด</option>
                    {
                      province.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))
                    }
                    {/* Add province options */}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="district_id" className="mb-3">
                  <Form.Label>เขต/อำเภอ</Form.Label>
                  <Form.Select name='district_id' value={district_id} onChange={handleSelectChange}>
                    <option >เลือกเขต/อำเภอ</option>
                    {
                      district.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    {/* Add district options */}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="sub_district_id" className="mb-3">
                  <Form.Label>แขวง/ตำบล</Form.Label>
                  <Form.Select name='sub_district_id' value={sub_district_id} onChange={handleSelectChange}>
                    <option >เลือกแขวง/ตำบล</option>
                    {
                      subdistrict.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    {/* Add district options */}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            {/* <Row>
              <Col lg={7}>
                <Stack direction='horizontal' className="location-stack" gap={3}>
                  <Form.Group controlId="longitude" className='location-form-group mb-3'>
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control
                      type="text"
                      className="location-input-no-margin"
                      name="longitude"
                      value={longitude}

                    />
                  </Form.Group>
                  <Form.Group controlId="latitude" className='location-form-group mb-3'>
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control
                      type="text"
                      className="location-input-no-margin"
                      name="latitude"
                      value={latitude}

                    />
                  </Form.Group>
                </Stack>
              </Col>
            </Row>


            <Row className="mt-4">
              <Col>
                <MapComponent setLatitude={setLatitude}
                  setLongitude={setLongitude} />
              </Col>
            </Row> */}

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Button variant="primary" type="submit" className="mt-4" onClick={handleSubmit}>
                ลงทะเบียน
              </Button>
            </div>

          </Form>
        </div>


      </Stack>
    </Container>
  )
}

export default Regis
