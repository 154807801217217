import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import ImageUploader from './ImageUpload'; // Ensure this path is correct
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
const TicketData = ({
  loading,
  Action,
  setFormData,
  token,
  setTicketProblems,
  formData,
  ticketProblems,
  show,
  disabled // Add disabled prop
}) => {
  console.log(
    "ticketProblems",
    ticketProblems
  )

  const [contractor_code, setContractorCode] = useState([]);
  const [assetProblem, setAssetProblem] = useState([]);
  const [assetInstall, setAssetInstall] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [contractor, setContractor] = useState([]);
  const [contractordefault, setContractordefault] = useState("Example Contractor");
  const fetchAssetProblems = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/asset-model-problems?contractor_id=${formData?.contractor_id || ""}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setAssetProblem(response.data);
    } catch (error) {
      console.error('Failed to fetch asset problems:', error);
    }
  };

  const fetchContractor = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/customer/contractors`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      const Data = response.data.data;
      setContractor(Data);
    } catch (error) {
      console.error("Failed to fetch contractors:", error);
    }
  };

  const fetchCustomer = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/customers`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setCustomers(response.data);
    } catch (error) {
      console.error('Failed to fetch customers:', error);
    }
  };

  const fetchAssetInstall = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/asset-installations?contractor_id=${formData?.contractor_id || ""}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setAssetInstall(response.data);
    } catch (error) {
      console.error('Failed to fetch asset installations:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'contractor_id') {
      setContractorCode(value);
    }
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleProblemChange = (e) => {
    const { name, value } = e.target;
    setTicketProblems((prevState) => ({ ...prevState, [name]: value }));
  };



  useEffect(() => {
    if (formData.contractor_id) {
      fetchAssetInstall();
      fetchAssetProblems();
    }
    fetchCustomer();
  }, [formData.contractor_id]);

  useEffect(() => {
    fetchContractor();
  }, []);

  useEffect(() => {
    if (Action === 'view') {
      if (ticketProblems) {
        console.log("ticketProblems", ticketProblems)
      }
      else {
        ticketProblems.ProductInstallation = "เครื่องพิมพ์เลเซอร์ขาวดำ"
      }

    }
  }, [Action]);


  return (
    <Container fluid className="contractor-container">
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <Row>
            <Col lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>รหัสใบแจ้งซ่อม</Form.Label>
                <Form.Control disabled value={formData?.Ticket_ID || ''} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>สินค้าที่ติดตั้ง</Form.Label>
                <Form.Control
                  type="text"
                  className="personal-form-control"
                  name="ProductInstallation"
                  value={ticketProblems?.ProductInstallation || ''}
                  onChange={handleProblemChange}
                  disabled={disabled} // Add disabled to Form.Control
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>ผู้ประกอบการ</Form.Label>
                <Form.Select
                  className="personal-form-control"
                  name="contractor_id"
                  value={contractor_code}
                  onChange={handleChange}
                  disabled={disabled}
                >
                  <option value="" disabled selected>{Action === 'view' ? contractordefault : 'กรุณาเลือกผู้ประกอบการ'}</option>
                  {contractor?.map((item, index) => (
                    <option key={index} value={item.contractor_id} index={index}>
                      {item.contractor_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>วันที่แจ้งซ่อม</Form.Label>
                <Form.Control
                  type="date"
                  className="personal-form-control"
                  name="TicketDate"
                  value={formData?.TicketDate ? formData.TicketDate.split('T')[0] : ''}
                  onChange={handleChange}
                  disabled={disabled} // Add disabled to Form.Control
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>อาการเสียสินค้า</Form.Label>
                <Form.Select
                  className="select-style"
                  name="productProblem"
                  value={ticketProblems?.productProblem || ''}
                  onChange={handleProblemChange}
                  disabled={disabled} // Add disabled to Form.Select
                >
                  <option value="" disabled selected>{Action === 'view' ? "ชำรุด" : 'เลือกอาการเสียของสินค้า'}</option>
                  <option key={1} value={1}>ปกติ</option>
                  <option key={2} value={2}>ชำรุด</option>
                  <option key={3} value={3}>สูญหาย</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>อาการอื่นๆ</Form.Label>
                <Form.Control
                  name="Comment"
                  disabled={disabled}
                  value={ticketProblems?.Comment || ''}
                  placeholder="กรุณากรอกอาการเสียสินค้าเพิ่มเติม"
                  onChange={handleProblemChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <Form.Group className="mb-3" controlId="formDetailTextarea">
                <Form.Label>รายละเอียด</Form.Label>
                <Form.Control
                  as="textarea"
                  name="Description"
                  rows={3}
                  value={ticketProblems?.Description || ''}
                  placeholder="กรุณากรอกรายละเอียด"
                  onChange={handleProblemChange}
                  disabled={disabled} // Add disabled to Form.Control
                />
              </Form.Group>
            </Col>
            <Col md={5}>
              <Form.Group className="mb-3" controlId="formNotesTextarea">
                <Form.Label>หมายเหตุ</Form.Label>
                <Form.Control
                  as="textarea"
                  name="Remark"
                  value={ticketProblems?.Remark || ''}
                  rows={3}
                  placeholder="กรุณากรอกหมายเหตุ"
                  onChange={handleProblemChange}
                  disabled={disabled} // Add disabled to Form.Control
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <div style={{ marginBottom: '16px', fontWeight: 600, color: 'rgba(74, 84, 94, 1)' }}>
                รูปสินค้า
              </div>
              <ImageUploader
                show={show}
                setTicketProblems={setTicketProblems}
                Edit={true}
                view={false}
                singleImage={false}
                ticketProblems={ticketProblems}
                disabled={disabled} // Pass disabled to ImageUploader if needed
              />
            </Col>
          </Row>
        </>

      )}
    </Container>
  );
};

export default TicketData;