import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    Tab,
    Nav,
    Row,
    Col,
    Stack,
} from 'react-bootstrap';
import Sheet from "./tabs/sheet";
import Customer from "./tabs/customer_installation";
import Responsible from "./tabs/respondible_tech";
import Equipment from "./tabs/equuipment";
import Document from "./tabs/doucument";
import Image from "./tabs/image"
import axios from 'axios';
import "./Modal.css"
import Cookies from 'js-cookie';
import Spinner from 'react-bootstrap/Spinner';

function TotalModal(props) {
    const [activeTab, setActiveTab] = useState('sheet');
    const [formData, setFormData] = useState({});
    const [doucumentData, setDoucumentData] = useState([]);
    const [responsibleData, setResponsibleData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [equipmentData, setEquipmentData] = useState([]);
    const tabOrder = ['sheet', 'customer', 'responsible', 'equipment', 'doucument', 'image'];
    const [ImageData, setImageData] = useState([]);
    console.log("ImageData", ImageData)
    const [assetData, setAssetData] = useState([]);


    const ClearData = () => {
        setFormData({});
        setDoucumentData([]);
        setResponsibleData([]);
        setCustomerData([]);
        setEquipmentData([]);
        setActiveTab('sheet');
    }

    const handleNextClick = () => {
        const currentIndex = tabOrder.indexOf(activeTab);
        const nextIndex = currentIndex + 1;

        if (nextIndex < tabOrder.length) {
            setActiveTab(tabOrder[nextIndex]);
        }
    };


    const handlePreviousClick = () => {
        const currentIndex = tabOrder.indexOf(activeTab);
        const previousIndex = currentIndex - 1;
        if (previousIndex >= 0) {
            setActiveTab(tabOrder[previousIndex]);
        }
    };



    const [loading, setLoading] = useState(false);

    const FetchSearch = async (documentid) => {
        setLoading(true);
        try {
            // Fetching all data in parallel
            const [response, attachments, imageResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_API_URL}/customer/installation-docs/${documentid}`, {
                    headers: { 'Authorization': `Bearer ${Cookies.get('token')}` }
                }),
                axios.get(`${process.env.REACT_APP_API_URL}/customer/installation-docs/${documentid}/attachments`, {
                    headers: { 'Authorization': `Bearer ${Cookies.get('token')}` }
                }),
                axios.get(`${process.env.REACT_APP_API_URL}/customer/installation-docs/${documentid}/work-images`, {
                    headers: { 'Authorization': `Bearer ${Cookies.get('token')}` }
                })
            ]);

            console.log(imageResponse.data);

            // Setting state with fetched data
            setDoucumentData(response.data.basic_info);
            setResponsibleData(response.data.technicians);
            setCustomerData(response.data.customer_site);
            setEquipmentData(attachments.data);
            // If you need to set image data, uncomment the following line
            setImageData(imageResponse.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            await new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
                setLoading(false);
            })

        }
    }


    useEffect(() => {
        if (props.show === true) {
            if (props.rows) {
                setFormData(props.rows);
                if (props.rows.doc_id) {
                    FetchSearch(props.rows.doc_id);
                }
                else {
                    setDoucumentData([]);
                    setResponsibleData([]);
                    setCustomerData([]);
                    setEquipmentData([]);
                }
            }
        }
    }, [props.rows, props.show]);

    useEffect(() => {
        if (props.show === false) {
            console.log("clear data")
            ClearData();
        }
    }, [props.show]);


    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ position: "absolute" }}
            backdrop="static"
        >
            <Modal.Header closeButton style={{ padding: "20px 24px" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {(() => {
                        switch (props.Action) {
                            case 'edit':
                                return 'ดูรายละเอียดงานทั้งหมด';
                            case 'view':
                                return 'ดูรายละเอียดงานทั้งหมด';
                            case 'create':
                                return 'ดูรายละเอียดงานทั้งหมด';
                            default:
                                return 'ดูรายละเอียดงานทั้งหมด';
                        }
                    })()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "0 24px" }}>

                <Row>
                    <Col>
                        <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                            <Row>
                                <Col sm={12} lg={12} md={12}>
                                    <Nav variant="tabs" className="d-flex  justify-content-between">
                                        <Stack direction='horizontal'>
                                            <Nav variant="tabs" className="nav-tabs">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="sheet">ใบงาน</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="customer">ลูกค้าและสถานที่ติดตั้ง</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="responsible">ช่างผู้รับผิดชอบ</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="equipment">รายการอุปกรณ์</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="doucument">เอกสารประกอบ</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="image">รูปภาพส่งงาน</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Stack>
                                    </Nav>
                                </Col>
                                {loading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Spinner animation="border" variant="primary" />
                                    </div>
                                ) : (
                                    <Col lg={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="sheet">
                                                <Sheet formData={doucumentData} setFormData={setFormData} disabled={true} Loaddraft={true} data={props.data} Action={props.Action} show={props.show} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="customer">
                                                <Customer formData={customerData} setFormData={setFormData} disabled={true} Loaddraft={true} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="responsible">
                                                <Responsible formData={responsibleData} setFormData={setFormData} disabled={true} Loaddraft={true} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="equipment">
                                                <Equipment asset={assetData} setAsset={setAssetData} disabled={true} Loaddraft={true} data={props.data} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="doucument">
                                                <Document asset={assetData} formData={equipmentData} setAsset={setAssetData} disabled={true} Loaddraft={true} data={props.data} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="image">
                                                <Image ImageData={ImageData} setAsset={setAssetData} disabled={true} Loaddraft={true} data={props.data} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                )}
                            </Row>
                        </Tab.Container>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "center", display: "flex" }}>
                <Stack direction='horizontal' style={{ justifyContent: "center", display: "flex" }} gap={5}>
                    {activeTab !== tabOrder[0] && (
                        <Button onClick={handlePreviousClick}>ย้อนกลับ</Button>
                    )}
                    {activeTab !== tabOrder[tabOrder.length - 1] && (
                        <Button onClick={handleNextClick}>ถัดไป</Button>
                    )}
                </Stack>
            </Modal.Footer>
        </Modal>
    )
}

export default TotalModal
