import React from 'react'
import { Container, Stack } from 'react-bootstrap';
import ReportPage from './ReportSystem/ReportPage';
import { useTranslation } from 'react-i18next';
function Report() {
  const { t } = useTranslation();
  return (
    <Container fluid className='main mt-5' style={{ padding: "0px" }}>
      <Stack direction='vertical' >
        <div className="page-title" data-aos="fade">
          <div className="heading">
            <div className="container">
              <div className="row d-flex justify-content-center text-center">
                <div className="col-lg-8">
                  <h1>{t("report_management")}</h1>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadcrumbs">
            <div className="container">
              <ol>
                <li><a href="/home">{t("home")}</a></li>
                <li className="current">{t("report_management")}</li>
              </ol>
            </div>
          </nav>
        </div>
        <div  data-aos="fade">
        <ReportPage /></div>
      </Stack>
    </Container>
  )
}

export default Report
