import { Row, Col, Container } from 'react-bootstrap'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import TotalSearch from './TotalSearch';
import TotalTable from './TotalTable';
import "../../Ticket/Ticket_sys/TicketManagement/TicketPage.css"
import Cookies from 'js-cookie';
function TotalPage() {
  const { t } = useTranslation();
  const [total, setTotal] = useState([]);
  const [backupTotal, setBackupTotal] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get( `${process.env.REACT_APP_API_URL}/customer/installation-docs/search `
        ,
        { headers: { 'Authorization': `Bearer ${Cookies.get('token')}` } }
      );
      setTotal(response.data);
      setBackupTotal(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      await new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
          setLoading(false);
      })
  }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (searchParams) => {
    
    console.log("searchParams", searchParams);
    const filteredData = backupTotal.filter((item) =>
      (!searchParams.doc_type ||
        item.doc_type.includes(searchParams.doc_type)) &&
      (!searchParams.doc_number ||
        item.doc_number.includes(searchParams.doc_number)) &&
      (!searchParams.status_text ||
        item.status_text.toString().includes(searchParams.status_text)) &&
      (!searchParams.doc_date ||
        new Date(item.doc_date).toISOString().slice(0, 10) === searchParams.doc_date)
    );
    console.log("filteredData", filteredData);
    setTotal(filteredData);
  };
  

  return (
    <div className="page-wrapper" id="main-wrapper" data-layout="vertical" style={{ padding: "0px" }}>
      <Container fluid style={{ width: "100%", padding: "69px 53px", maxWidth: "1320px" }}>
        <Row>
          <Col lg={12}>
            <div className="body-wrapper">
              {/* <Header /> */}
              <div className="Ticket-title" ><h2 >{t('total_management')}</h2></div>
              <TotalSearch onSearch={handleSearch} />
              <TotalTable total={total} loading={loading} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TotalPage
