import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import ImageUploader from "./ImageUploader"
import Cookies from 'js-cookie';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';

function ProductData({ disabled, show, data }) {
    console.log("disabled", disabled)
    console.log("data", data)
    const [ProductData, setProductData] = useState([]);
    console.log("ProductData", ProductData)
    const [loading, setLoading] = useState(false);

    const FetchProductData = async () => {
        setLoading(true);
        const asset_id = data?.auto_id;
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/customer/asset-installations/details`
                , {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    }
                }
            );

            const data = await response.data;
            const FilterDetail = data.find((item) => item.auto_id === asset_id);
            console.log("FilterDetail", FilterDetail)
            setProductData(FilterDetail);
        } catch (error) {
            console.error('Error fetching ProductData:', error);
        } finally {
            await new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
                setLoading(false);
            })

        }
    }

    useEffect(() => {
        FetchProductData();
    }, [data]);

    return (
        <Container fluid className="contractor-container">
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : (
                <Row>
                    {/* Left Column */}
                    <Col lg={6}>
                        <Form>
                            {/* Form Fields */}
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="contractorId">
                                        <Form.Label>ชื่อสินค้า</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={ProductData?.asset_model_name}
                                            disabled={disabled}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="phone">
                                        <Form.Label>รหัสสินค้า</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={ProductData?.asset_model_code}
                                            disabled={disabled}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="contactName">
                                        <Form.Label>แบรนด์สินค้า</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={ProductData?.brand_name}
                                            disabled={disabled}>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="address">
                                        <Form.Label>Serial Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            disabled={disabled}
                                            value={ProductData?.stock_sn_id || 'SN123456789'}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col lg={6}>
                                    <Form.Group controlId="province" className="mb-3">
                                        <Form.Label>สถานะสินค้า</Form.Label>
                                        <Form.Select value={ProductData?.status === 1 ? "รอแก้ไข" : "แก้ไขสำเร็จ"} disabled={disabled}>
                                            <option value="ใช้งาน">ใช้งาน</option>
                                            <option value="รอแก้ไข">รอแก้ไข</option>
                                            <option value="แก้ไขสำเร็จ">แก้ไขสำเร็จ</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group controlId="district" className="mb-3">
                                        <Form.Label>สถานะประกัน</Form.Label>
                                        <Form.Select
                                            value={ProductData?.warranty_status === 1 ? "อยู่ในช่วงประกัน" : "หมดช่วงประกัน"}
                                            disabled={disabled}>
                                            <option value="อยู่ในช่วงประกัน">อยู่ในช่วงประกัน</option>
                                            <option value="หมดช่วงประกัน">หมดช่วงประกัน</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col lg={6}>
                                    <Form.Group controlId="province" className="mb-3">
                                        <Form.Label>วันที่ติดตั้ง</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={ProductData?.installation_date ? ProductData.installation_date.split('T')[0] : ''}
                                            disabled={disabled}>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group controlId="district" className="mb-3">
                                        <Form.Label>วันที่หมดประกัน</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={ProductData?.end_warranty ? ProductData.end_warranty.split('T')[0] : ''}
                                            disabled={disabled}>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                        </Form>
                    </Col>

                    {/* Right Column */}
                    <Col>
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group controlId="search">
                                        <Form.Label>รูปสินค้า</Form.Label>
                                        <ImageUploader
                                            show={show}
                                            setProductData={setProductData}
                                            singleImage={false}
                                            ProductData={ProductData}
                                            disabled={true}
                                        />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10}>
                                    <Form.Group controlId="search">
                                        <Form.Label>รายละเอียด</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={ProductData?.description}
                                            placeholder="รายละเอียด"
                                            disabled={disabled}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={10}>
                                    <Form.Group controlId="search">
                                        <Form.Label>หมายเหตุ</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={ProductData?.remark}
                                            placeholder="หมายเหตุ"
                                            disabled={disabled}

                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            )}
        </Container>
    )
}

export default ProductData
