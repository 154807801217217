import React, {  useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Searchicon from '../../../Page/webproj/assets/img/searchicon.svg';
import "../../Ticket/Ticket_sys/TicketManagement/checklistsearch.css"
import { useTranslation } from 'react-i18next';
function TotalSearch({ onSearch }) {
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useState({
        doc_type: '',
        doc_number: '',
        status_text: '',
        doc_date: '',
    })
    const handleSearch = () => {
        onSearch(searchParams);  // Trigger search in parent
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams((prev) => ({ ...prev, [name]: value }));
    };

    const ClearSearch = () => {
        const ClearSearchparams = {
            doc_type: '',
            doc_number: '',
            status_text: '',
            doc_date: '',
        };
        setSearchParams(ClearSearchparams);
        onSearch(ClearSearchparams);
    };

    return (
        <Container fluid className="checklistsearch-container container-style" style={{
            padding: '16px 24px',
            borderRadius: '12px',
            border: '1px solid rgba(230, 233, 244, 1)',
            boxShadow: '0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.2)',
            marginBottom: "20px",
            maxWidth: "1320px"
        }}
        >
            <Row >
                <Col>
                    <Form.Label className="search-header">{t('search')}</Form.Label>
                </Col>
            </Row>
            <Row style={{ marginBottom: "8px" }}>
                <Col>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>ประเภทใบงาน</Form.Label>
                        <div className="input-wrapper">
                            <Form.Select
                                name='doc_type'
                                type='text'
                                value={searchParams.doc_type}
                                onChange={handleInputChange}
                                className="form-controlsearch"
                            >
                                <option value="">เลือกประเภทใบงาน</option>
                                <option value="installation">installation</option>
                                <option value="CM">CM</option>
                                <option value="PM">PM</option>
                                <option value="Repair">Repair</option>
                                <option value="อื่นๆ">อื่นๆ</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>เลขที่ใบงาน</Form.Label>
                        <div className="input-wrapper">
                            <Form.Control
                                onChange={handleInputChange}
                                type='text'
                                name='doc_number'
                                className="form-controlsearch"
                                value={searchParams.doc_number}
                            />
                        </div>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>สถานะใบงาน</Form.Label>
                        <div className="input-wrapper">
                            <Form.Select
                                name='status_text'
                                type='text'
                                onChange={handleInputChange}
                                className="form-controlsearch"
                                value={searchParams.status_text}
                            >
                                <option value="">เลือกอสถานะใบงาน</option>
                                <option value="รอติดตั้ง">รอติดตั้ง	</option>
                                <option value="ไม่ทราบสถานะ	">ไม่ทราบสถานะ</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>วันที่ใบงาน</Form.Label>
                        <div className="input-wrapper">
                            <Form.Control
                                name='doc_date'
                                type='date'
                                onChange={handleInputChange}
                                value={searchParams.doc_date}
                                className="form-controlsearch"
                            />
                        </div>
                    </Form.Group>
                </Col>
                <Col
                    lg={1}
                    sm={0}
                    md={0}
                    className="button-container1"
                >
                    <Form.Group className='form-group'>
                        <Button
                            className="search-button"
                            onClick={handleSearch}
                            onDoubleClick={ClearSearch}
                        >
                            <img src={Searchicon} alt="searchicon" />
                            <span>ค้นหา</span>

                        </Button>
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    )
}

export default TotalSearch
