import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Tab, Nav, Stack, Button } from 'react-bootstrap';
import Sheet from "./tabs/sheet";
import Customer from "./tabs/customer_installation";
import Responsible from "./tabs/respondible_tech";
import Equipment from "./tabs/equuipment";
import Document from "./tabs/doucument";
import Image from "./tabs/image"
import axios from 'axios';
import Cookies from 'js-cookie';
import Spinner from 'react-bootstrap/Spinner';
function RepairModal({
    Assetdata,
    show,
    rowsData,
    sethistoryshow,
    disabled,
    Action
}) {
    const [activeTab, setActiveTab] = useState('sheet');
    const [formData, setFormData] = useState({});
    const [doucumentData, setDoucumentData] = useState([]);
    const [responsibleData, setResponsibleData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [equipmentData, setEquipmentData] = useState([]);
    const tabOrder = ['sheet', 'customer', 'responsible', 'equipment', 'doucument', 'image'];
    const [ImageData, setImageData] = useState([]);
    const [sheet, setSheet] = useState([])
    const [loading, setLoading] = useState(false);

    const handleNextClick = () => {
        const currentIndex = tabOrder.indexOf(activeTab);
        const nextIndex = currentIndex + 1;
        if (nextIndex < tabOrder.length) {
            setActiveTab(tabOrder[nextIndex]);
        }
    };


    const handlePreviousClick = () => {
        const currentIndex = tabOrder.indexOf(activeTab);
        const previousIndex = currentIndex - 1;
        if (previousIndex >= 0) {
            setActiveTab(tabOrder[previousIndex]);
        }
    };

    const ClearData = () => {
        setFormData({});
        setSheet([]);
        setDoucumentData([]);
        setResponsibleData([]);
        setCustomerData([]);
        setEquipmentData([]);
        setActiveTab('sheet');
    }



    const FetchRepairHistory = async () => {
        const asset_id = Assetdata?.auto_id;
        const installation_doc_id = parseInt(rowsData.doc_id, 10);
        console.log(asset_id);
        console.log(installation_doc_id);

        try {
            setLoading(true); // เริ่มแสดงอนิเมชันการโหลด

            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/customer/asset-installations/${asset_id}/repair/${installation_doc_id}`,
                {
                    headers: { Authorization: `Bearer ${Cookies.get('token')}` },
                }
            );


            setSheet(response.data.work_order);
            setDoucumentData(response.data.documents);
            setCustomerData(response.data.customer_location);
            setResponsibleData(response.data.technicians);
            setEquipmentData(response.data.equipment);
            setImageData(response.data.delivery_images);

            console.log("response.data", response.data);
        } catch (error) {
            console.error(error);
        } finally {
            await new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
                setLoading(false);
            })
        }
    };


    useEffect(() => {
        if (show === true) {
            if (rowsData) {
                setFormData(rowsData);
                FetchRepairHistory()
            }
            else {
                ClearData()
            }
        }
    }, [show,rowsData]);

    useEffect(() => {
        if (show === false) {
            ClearData();
        }
    }, [show]);
    return (
        <Modal
            show={show}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            onHide={() => sethistoryshow(false)}
        >
            <Modal.Header closeButton style={{ padding: "20px 24px" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {(() => {
                        switch (Action) {
                            case 'PM':
                                return 'ดูรายละเอียดใบงานการเข้าPM';
                            case 'CM':
                                return 'ดูรายละเอียดใบงานการเข้าติดตั้ง';
                            case 'repair':
                                return 'ดูรายละเอียดใบงานการซ่อม';
                            default:
                                return 'ดูรายละเอียดใบงาน';
                        }
                    })()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "0 24px" }}>
            {loading ? (
                    <Spinner animation="border" variant="primary" />
                ) : (
                    <Row>
                        <Col>
                            <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                                <Row>
                                    <Col sm={12} lg={12} md={12}>
                                        <Nav variant="tabs" className="d-flex  justify-content-between">
                                            <Stack direction='horizontal'>
                                                <Nav variant="tabs" className="nav-tabs">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="sheet">ใบงาน</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="customer">ลูกค้าและสถานที่ติดตั้ง</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="responsible">ช่างผู้รับผิดชอบ</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="equipment">รายการอุปกรณ์</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="doucument">เอกสารประกอบ</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="image">รูปภาพส่งงาน</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Stack>
                                        </Nav>
                                    </Col>
                                    <Col lg={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="sheet">
                                                <Sheet formData={sheet} setFormData={setFormData} disabled={true} Loaddraft={false} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="customer">
                                                <Customer formData={customerData} setFormData={setFormData} disabled={true} Loaddraft={false} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="responsible">
                                                <Responsible formData={responsibleData} setFormData={setFormData} disabled={true} Loaddraft={false} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="equipment">
                                                <Equipment asset={Assetdata} disabled={true} Loaddraft={false} EquipmentData={equipmentData} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="doucument">
                                                <Document asset={Assetdata} formData={formData} disabled={true} Loaddraft={false} DoucumentData={doucumentData} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="image">
                                                <Image ImageData={ImageData} disabled={true} Loaddraft={false} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Col>
                    </Row>
                )}
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "center", display: "flex" }}>
                <Stack direction='horizontal' style={{ justifyContent: "center", display: "flex" }} gap={5}>
                    {activeTab !== tabOrder[0] && (
                        <Button onClick={handlePreviousClick}>ย้อนกลับ</Button>
                    )}
                    {activeTab !== tabOrder[tabOrder.length - 1] && (
                        <Button onClick={handleNextClick}>ถัดไป</Button>
                    )}
                </Stack>
            </Modal.Footer>
        </Modal>
    )
}

export default RepairModal
