import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
import Cookies from 'js-cookie';
import LOGO from "../../../../assets/img/sunpeaktechlogo/cojlogo.png";
// import LOGO from "../../../../assets/img/logo.png"
import { useTranslation } from 'react-i18next';


const LoginPage = () => {
  console.log('Environment Variables:', process.env);
  console.log("REACT_APP_API_URL", process.env.REACT_APP_API_URL)
  const [username_or_email, setUsername] = useState('');
  console.log("username_or_email", username_or_email)
  const [password, setPassword] = useState('');
  console.log("password", password)
  const [remember_me, setRemember] = useState(false);
  const [error, setError] = useState('');
  const [response, setResponse] = useState('');
  console.log("response", response)
  const navigate = useNavigate();
  const { t } = useTranslation();



  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await axios.post(`${process.env.REACT_APP_API_URL}/customers/login`, {
        username_or_email,
        password,
        remember_me
      });
      setResponse(result);
      setError('');

      Cookies.set('token', result.data.token, { expires: 7 });  // Cookie expires in 7 days
      Cookies.set('username', result.data.username, { expires: 7 }); // เก็บ username ไว้ใน cookies
      navigate('/home');

      Swal.fire({
        title: "สำเร็จ",
        text: "เข้าสู่ระบบสำเร็จ",
        icon: "success",
        confirmButtonColor: "#FF0000",
    });
    

    } catch (err) {
      const errorMessage = err.response ? err.response.data : err.message;
      console.log('Error:', errorMessage);
      setError(errorMessage);
      setResponse('');

      Swal.fire({
        title: "ข้อผิดพลาด",
        text: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
        icon: "error",
        confirmButtonColor: "#FF0000",
    });
    
      navigate('/login');
    }
  };


  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            <div className="position-relative overflow-hidden radial-gradient login-page d-flex align-items-center justify-content-center" data-aos="fade" >
              <div className="d-flex align-items-center justify-content-center w-100">
                <div className="row justify-content-center w-100">
                  <div className="col-sm-6 col-md-6 col-lg-6 mx-auto">
                    <div className="card mb-0">
                      <div className="card-body">
                        <a href="#" className="text-nowrap logo-img text-center d-block py-3 w-100">
                          <img src={LOGO} width="129px" alt="Logo" />
                        </a>
                        {/* <div style={{ textAlign: 'center' }}>
                          <h4>Court of Justice Asset Management</h4>
                          <p>By Sunpeaktech Platform</p>
                        </div> */}

                        <form onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <label htmlFor="username" className="form-label">{t('username')} (Username)</label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              value={username_or_email}
                              onChange={(e) => setUsername(e.target.value)}
                              required
                              placeholder="กรอกชื่อผู้ใช้งานระบบ (Username)"
                            />
                          </div>
                          <div className="mb-4">
                            <label htmlFor="password" className="form-label">{t('password')}</label>
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                              placeholder="กรอกรหัสผ่าน"
                            />
                          </div>
                          <div className="d-flex align-items-center justify-content-between mb-4">
                            <div className="form-check">
                              <input className="form-check-input primary"
                                type="checkbox"
                                value={remember_me}
                                onChange={(e) => setRemember(e.target.checked)}
                                id="flexCheckChecked" />
                              <label className="form-check-label text-dark" htmlFor="flexCheckChecked">
                                {t('keepme')}
                              </label>
                            </div>
                          </div>
                          <Button className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2" type="submit">
                            {t('login')}
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};


export default LoginPage;
