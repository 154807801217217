import React, { useState, useEffect } from 'react';
import { Container, Table } from 'react-bootstrap';
import Cookies from 'js-cookie';
import axios from 'axios';
import PMModal from './PMModal';
import Spinner from 'react-bootstrap/Spinner';
function PMHistory({
    disabled,
    data
}
) {
    console.log("data", data)
    const [RowsData, setRowsData] = useState([])
    const [PMHistory, setPMHistory] = useState([])
    const [historyshow, sethistoryshow] = useState(false)
    const columns = [
        { header: 'ลำดับ', key: 'index' },
        { header: 'รหัสใบงาน PM', key: 'doc_number', },
        { header: 'วันที่ติดตั้ง', key: 'maintenance_date', },
        { header: 'ลิ้งดูใบงาน', key: 'actions', },
    ];

    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selectedRows, setSelectedRows] = useState({});
    const paginationModel = { page: 0, pageSize: 10 };

    const [loading, setLoading] = useState(false);

    const FetchPMHistory = async () => {
        setLoading(true);
        try {
            const token = Cookies.get('token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/customer/asset-installations/maintenance-history `, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.data;
            console.log(data)
            setPMHistory(data);
        } catch (error) {
            console.error('Error fetching PM history:', error);
        } finally {
            await new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
                setLoading(false);
            })
        }
    }

    const handleCheckboxChange = (index) => {
        setSelectedRows(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };
    const handleshowhistory = (data) => {
        sethistoryshow(true); // Show the modal
        setRowsData(data); // Set the data for the modal
    }

    const handleChangeDate = (dateString) => {
        if (!dateString) return ""; // Handle empty or undefined date

        // Parse the dateString into a JavaScript Date object
        const date = new Date(dateString);

        // Format date to Thai format using `toLocaleDateString`
        const formattedDate = date.toLocaleDateString("th-TH", {
            day: "numeric",
            month: "long",
            year: "numeric"
        });

        return `${formattedDate}`;
    };

    useEffect(() => {
        FetchPMHistory();
    }, [])

    return (
        <Container fluid className="contractor-container">
            <Table hover responsive size='md' className="table-border" style={{ padding: "0px", margin: "0px" }}>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner animation="border" variant="primary" />
                    </div>
                ) : (
                    <>
                        <thead>
                            <tr>
                                {columns.map(col => (
                                    <th key={col.key} className={`work-table-header custom-background ${col.Name}`}>
                                        {col.header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {PMHistory
                                ?.slice(
                                    paginationModel.page * paginationModel.pageSize,
                                    (paginationModel.page + 1) * paginationModel.pageSize
                                )
                                ?.map((row, rowIndex) => (
                                    <tr key={row.index} className="work-table-row">
                                        {columns.map(col => (
                                            <td key={col.key} className={`work-table-cell ${col.Name}`}>
                                                {col.key === 'checkbox' ? (
                                                    <input
                                                        type="checkbox"
                                                        checked={!!selectedRows[row.index]}
                                                        onChange={() => handleCheckboxChange(row.index)}
                                                    />
                                                ) : col.key === 'actions' ? (
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div
                                                            onClick={() => handleshowhistory(row)} // Pass function reference
                                                            style={{
                                                                color: 'rgba(93, 135, 255, 1)',
                                                                borderBottom: '1px solid rgba(93, 135, 255, 1)',
                                                                cursor: 'pointer',
                                                                width: 'fit-content'
                                                            }}
                                                        >
                                                            ดูรายละเอียด
                                                        </div>
                                                    </div>
                                                ) : col.key === "maintenance_date" ? (
                                                    handleChangeDate(row[col.key])
                                                ) : col.key === 'index' ? (
                                                    rowIndex + 1
                                                ) : (
                                                    row[col.key]
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </>
                )}
                <PMModal
                    show={historyshow}
                    rowsData={RowsData}
                    sethistoryshow={sethistoryshow}
                    disabled={disabled}
                    centered
                    Assetdata={data}
                    Action={"PM"}
                />
            </Table>

        </Container>
    )
}

export default PMHistory
