import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';
function Footer() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [user, setUser] = useState(false);
    useEffect(() => {
        const token = Cookies.get('token');
        if (!token) {
            // ถ้าไม่มี token ให้เปลี่ยนเส้นทางไปยังหน้า Login
            // navigate('/login');
        } else {
            setUser(true);
        }
    }, [navigate]);
    return (
        <footer id="footer" className="footer dark-background">
            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="col-lg-4 col-md-6 footer-about">
                            <a href="index.html" className="logo d-flex align-items-center">
                                <span className="sitename">SunPeakTech</span>
                            </a>
                            <div className="footer-contact pt-3">
                                <p> 6/122 ซ.คู้บอน 27 แขวงท่าแร้ง เขตบางเขน กรุงเทพมหานคร</p>
                                {/* <p>New York, NY 535022</p> */}
                                <p className="mt-3">
                                    <strong>Phone:</strong> <span>086-062-4888</span>
                                </p>
                                <p>
                                    <strong>Email:</strong> <span>sales@sunpeaktech.com</span>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <Link to={user ? '/home' : '/login'}> {t('home')}</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="#"> {t('services')}</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="#">{t('aboutUs')}</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i><Link to={user ? '/assetinstallation' : '/login'}><span>{t('installedProductManagement')}</span></Link></li>
                                <li><i className="bi bi-chevron-right"></i><Link to={user ? '/ticket' : '/login'}><span>{t('ticketManagement')}</span></Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to={user ? '/alltaskmanagement' : '/login'}>{t('total_management')}</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to={user ? '/report' : '/login'}>{t('ticketReport')}</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="copyright">
                <div className="container text-center">
                    <p>
                        © <span>Copyright and Design :</span> <strong className="px-1 sitename">SunPeakTech</strong>{" "}
                     
                    </p>
                </div>
            </div> */}
        </footer>

    )
}

export default Footer
