import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Table, Stack, Pagination } from 'react-bootstrap';
import arrow from "../../../webproj/assets/img/arrow.svg";
import TicketModal from "./TicketModal"
import "../Work_management/work_table.css"
import Cookies from 'js-cookie';
import Spinner from 'react-bootstrap/Spinner';

function ToolbarComponent({ onAddClick, onBulkDeleteClick, setAction, Action, token }) {
    const [modalShow, setModalShow] = useState(false);

    // const [deleteModalShow, setDeleteModalShow] = useState(false);

    return (
        <Container fluid className="checklist-toolbar-container">
            <Row>
                <Col style={{ padding: "0" }}>
                    <div className="work-toolbar-heading">ตารางแสดงข้อมูลแจ้งซ่อมสินค้า</div>
                </Col>
                <Col style={{ padding: "0" }}>
                    <Stack direction="horizontal" className='work-stack-button'>
                        <Button
                            onClick={() => {
                                setModalShow(true)
                                setAction("add")
                            }
                            }
                            size="lg"
                            variant="primary"
                            className="work-toolbar-button-add"
                        >
                            แจ้งซ่อม
                        </Button>
                        <TicketModal
                            setModalShow={setModalShow}
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            centered
                            backdrop="static"
                            Action={Action}
                            token={token}
                            Data={null}
                        />
                    </Stack>
                </Col>
            </Row>
        </Container>
    );
}


function CustomPagination({ page, pageCount, onPageChange, selectedCount, dataLength }) {
    const handlePrevious = () => {
        if (page > 0) {
            onPageChange(page - 1);
        }
    };

    const handleNext = () => {
        if (page < pageCount - 1) {
            onPageChange(page + 1);
        }
    };


    const paginationItems = [];
    for (let number = 1; number <= pageCount; number++) {
        paginationItems.push(
            <Pagination.Item
                key={number}
                active={number === page + 1} // Displayed page numbers start from 1
                onClick={() => onPageChange(number - 1)} // `number - 1` to match zero-indexed `page`
                className="work-pagination-item"
            >
                {number}
            </Pagination.Item>
        );
    }

    return (
        <Container className="work-pagination-container" >
            <Pagination >
                <Pagination.Prev
                    onClick={handlePrevious}
                    disabled={page === 0}
                    className="work-pagination-prev"
                >
                    Prev
                </Pagination.Prev>
                {paginationItems}
                <Pagination.Next
                    onClick={handleNext}
                    disabled={page >= pageCount - 1}
                    className="work-pagination-next"
                >
                    Next
                </Pagination.Next>
            </Pagination>

            <Stack direction='horizontal' className="work-results-count" style={{ visibility: "hidden" }}>
                <div>{0}</div>
                <div>results</div>
                <img src={arrow} alt="arrow icon" />
            </Stack>

        </Container>
    );
}


export default function CustomPaginationGrid({ ticketData, loading }) {
    console.log("ticketData", ticketData)
    const [modalShow, setModalShow] = useState(false);
    const [rows, setRows] = useState([]);
    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
    const [selectedRows, setSelectedRows] = useState({});
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [data, setData] = useState({ rows: [], columns: [] });
    const token = Cookies.get('token');
    const [Action, setAction] = useState("add");
    const [view, setView] = useState("view");
    const columns = [
        { header: 'ลำดับ', key: 'index', Name: "col-thin" },
        { header: 'รหัสผู้ประกอบการ', key: 'contractor_id', Name: "col-wide" },
        { header: 'ชื่อผู้ประกอบการ', key: 'contractor_name', Name: "col-wide" },
        { header: 'รหัสใบงานแจ้งซ่อม', key: 'Ticket_ID', Name: "col-wide" },
        { header: 'วัน-เวลา ที่แจ้ง', key: 'TicketDate', Name: "col-wide" },
        { header: 'สถานะแจ้งซ่อม', key: 'Status', Name: "col-wide" },
        { key: 'actions', Name: "col-thin" },
    ];


    const handlePageChange = (newPage) => {
        setPaginationModel((prev) => ({ ...prev, page: newPage }));
    };

    const handleChangeDate = (dateString) => {
        if (!dateString) return ""; // Handle empty or undefined date

        // Parse the dateString into a JavaScript Date object
        const date = new Date(dateString);

        // Format date to Thai format using `toLocaleDateString`
        const formattedDate = date.toLocaleDateString("th-TH", {
            day: "numeric",
            month: "long",
            year: "numeric"
        });

        // Extract time in HH:mm format (24-hour format)
        const formattedTime = date.toLocaleTimeString("th-TH", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false // Ensure 24-hour format
        });

        return `${formattedDate} ${formattedTime} น.`;
    };

    const handleshowTicket = (data) => {
        setModalShow(true);
        setRows(data);
    }


    useEffect(() => {
        setData({ rows: ticketData || [], columns: columns });
    }, [ticketData]);



    return (
        <Container fluid className="work-table-container">
            {/* <ToolbarComponent onAddClick={() => setDeleteModalShow(true)} onBulkDeleteClick={handleBulkDelete} /> */}
            <ToolbarComponent onAddClick={() => setDeleteModalShow(true)} setAction={setAction} Action={Action} token={token} />
            <Table hover responsive className="table-boder" >
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner animation="border" variant="primary" />
                    </div>
                ) : (
                    <>
                        <thead>
                            <tr>
                                {data.columns.map(col => (
                                    <th
                                        key={col.key}
                                        className={`work-table-header custom-background ${col.Name}`}
                                    >
                                        {col.header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.rows
                                .slice(
                                    paginationModel.page * paginationModel.pageSize,
                                    (paginationModel.page + 1) * paginationModel.pageSize
                                )
                                .map(row => (
                                    <tr key={row.auto_id} className="work-table-row">
                                        {data.columns.map(col => (
                                            <td key={col.key} className={`work-table-cell ${col.Name} `}>
                                                {col.key === "Status" ? (
                                                    <>
                                                        {row[col.key] === 1 && (
                                                            <span className="work-status work-status-open">
                                                                รอแก้ไข
                                                            </span>
                                                        )}
                                                        {row[col.key] === 2 && (
                                                            <span className="work-status work-status-close">
                                                                กำลังแก้ไข
                                                            </span>
                                                        )}
                                                        {row[col.key] === 3 && (
                                                            <span className="work-status work-status-pending">
                                                                แก้ไขสำเร็จ
                                                            </span>
                                                        )}
                                                        {row[col.key] !== 1 && row[col.key] !== 2 && row[col.key] !== 3 && (
                                                            <span className="work-status work-status-close">
                                                            </span>
                                                        )}
                                                    </>
                                                ) : col.key === "TicketDate" ? (
                                                    // Format and display date
                                                    handleChangeDate(row[col.key])
                                                ) : col.key === 'actions' ? (
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <div
                                                            onClick={() => {
                                                                handleshowTicket(row)
                                                                // setSelectDataRow(row)
                                                            }}
                                                            style={{ textAlign: 'center', color: 'rgba(93, 135, 255, 1)', borderBottom: '1px solid rgba(93, 135, 255, 1)', cursor: 'pointer' }}
                                                        >
                                                            ดูรายละเอียด
                                                        </div>
                                                    </div>
                                                ) :
                                                    col.key === 'index' ? (
                                                        data.rows.indexOf(row) + 1
                                                    ) : (
                                                        row[col.key]
                                                    )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </>
                )}
                <TicketModal
                    loading={loading}
                    setModalShow={setModalShow}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    centered
                    backdrop="static"
                    Action={view}
                    token={token}
                    Data={rows}
                />
            </Table>
            <CustomPagination
                page={paginationModel.page}
                pageCount={Math.ceil(data.rows.length / paginationModel.pageSize)}
                onPageChange={handlePageChange}
                selectedCount={Object.values(selectedRows).filter(Boolean).length}
                dataLength={data.rows.length}
            />
        </Container>

    );

}
