import React, { useEffect, useState } from 'react';
import {
    Button,
    Modal,
    Tab,
    Nav,
    Row,
    Col,
    Stack,
} from 'react-bootstrap';
import ProductData from './Tabs/ProductData';
import InstallationHistory from './Tabs/InstallationHistory';
import PMHistory from './Tabs/PMHistory';
import RepairHistory from './Tabs/RepairHistory';


function TicketModal(props) {

    console.log(props.Data)
    const [activeTab, setActiveTab] = useState('ProductData');
    const tabOrder = ['ProductData', "InstallationHistory", "PMHistory", 'RepairHistory'];
    // const [fileimg, setfileimg] = useState([]);
    // const [delImageList, setDelImageList] = useState([]);
    const ClearData = () => {
        setActiveTab('ProductData');
    }
    const handleNextClick = () => {
        const currentIndex = tabOrder.indexOf(activeTab);
        const nextIndex = currentIndex + 1;
        if (nextIndex < tabOrder.length) {
            setActiveTab(tabOrder[nextIndex]);
        }
    };

    const handlePreviousClick = () => {
        const currentIndex = tabOrder.indexOf(activeTab);
        const previousIndex = currentIndex - 1;
        if (previousIndex >= 0) {
            setActiveTab(tabOrder[previousIndex]);
        }
    };

    useEffect(() => {
        if (props.show === false) {
            ClearData();
        }
    }, [props.show]);


    return (
        <Modal
            {...props}
            size='xl'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton style={{ padding: "20px 24px" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {(() => {
                        switch (props.Action) {
                            case 'edit':
                                console.log(props.Action)
                                return 'รายละเอียดข้อมูลสินค้า';
                            case 'view':
                                console.log(props.Action)
                                return 'รายละเอียดข้อมูลสินค้า';
                            case 'add':
                                console.log(props.Action)
                                return 'รายละเอียดข้อมูลสินค้า';
                            default:
                                console.log(props.Action)
                                return 'รายละเอียดข้อมูลสินค้า';
                        }
                    })()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "0 24px" }}>
                <Row>
                    <Col>
                        <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                            <Row>
                                <Col sm={12} lg={12} md={12}>
                                    <Nav variant="tabs" className="d-flex justify-content-between">
                                        <Stack direction='horizontal'>
                                            <Nav.Item>
                                                <Nav.Link eventKey="ProductData">ข้อมูลสินค้า</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="InstallationHistory">ประวัติการเข้าติดตั้ง</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="PMHistory">ประวัติการเข้า PM</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="RepairHistory">ประวัติการซ่อม</Nav.Link>
                                            </Nav.Item>

                                        </Stack>
                                    </Nav>
                                </Col>
                                <Col lg={12}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="ProductData">
                                            <ProductData
                                                show={props.show}
                                                disabled={props.disabled}
                                                data={props.Data}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="InstallationHistory">
                                            <InstallationHistory
                                                data={props.Data}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="PMHistory">
                                            <PMHistory
                                                disabled={props.disabled}
                                                data={props.Data}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="RepairHistory">
                                            <RepairHistory
                                                data={props.Data}
                                            />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "center", display: "flex" }}>
                <Stack direction='horizontal' style={{ justifyContent: "center", display: "flex" }} gap={5}>
                    {activeTab !== tabOrder[0] && (
                        <Button onClick={handlePreviousClick}>ย้อนกลับ</Button>
                    )}
                    {activeTab !== tabOrder[tabOrder.length - 1] && (
                        <Button onClick={handleNextClick}>ถัดไป</Button>
                    )}
                </Stack>
            </Modal.Footer>
        </Modal>
    );
}

export default TicketModal;
