import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Stack, Pagination, } from 'react-bootstrap';
import arrow from "../../../Page/webproj/assets/img/arrow.svg";
import "../../Ticket/Ticket_sys/Work_management/work_table.css"
import Cookies from 'js-cookie';
import Spinner from 'react-bootstrap/Spinner';
import AssetInstallModal from "./AssetModal"
function ToolbarComponent({ onAddClick, onBulkDeleteClick, setAction, Action, token }) {

    return (
        <Container fluid className="checklist-toolbar-container">
            <Row>
                <Col style={{ padding: "0" }}>
                    <div className="work-toolbar-heading">ระบบจัดการข้อมูลสินค้าที่ติดตั้ง</div>
                </Col>
                <Col style={{ padding: "0" }}>
                    <Stack direction="horizontal" className='work-stack-button'>
                    </Stack>
                </Col>
            </Row>
        </Container>
    );
}


function CustomPagination({ page, pageCount, onPageChange, dataLength }) {
    const handlePrevious = () => {
        if (page > 0) {
            onPageChange(page - 1);
        }
    };

    const handleNext = () => {
        if (page < pageCount - 1) {
            onPageChange(page + 1);
        }
    };


    const paginationItems = [];
    for (let number = 1; number <= pageCount; number++) {
        paginationItems.push(
            <Pagination.Item
                key={number}
                active={number === page + 1} // Displayed page numbers start from 1
                onClick={() => onPageChange(number - 1)} // `number - 1` to match zero-indexed `page`
                className="work-pagination-item"
            >
                {number}
            </Pagination.Item>
        );
    }

    return (
        <Container className="work-pagination-container" >
            <Pagination >
                <Pagination.Prev
                    onClick={handlePrevious}
                    disabled={page === 0}
                    className="work-pagination-prev"
                >
                    Prev
                </Pagination.Prev>
                {paginationItems}
                <Pagination.Next
                    onClick={handleNext}
                    disabled={page >= pageCount - 1}
                    className="work-pagination-next"
                >
                    Next
                </Pagination.Next>
            </Pagination>

            <Stack direction='horizontal' className="work-results-count" style={{ visibility: "hidden" }}>
                <div>{0}</div>
                <div>results</div>
                <img src={arrow} alt="arrow icon" />
            </Stack>

        </Container>
    );
}


export default function AssetInstallTable({ assetInstall, loading }) {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
    const [modalShow, setModalShow] = useState(false);
    const [data, setData] = useState({ rows: [], columns: [] });
    console.log("assetInstall", assetInstall)
    const token = Cookies.get('token');
    const [Action, setAction] = useState("");
    const [rows, setRows] = useState([]);
    const columns = [
        { header: 'ลำดับ', key: 'index' },
        { header: 'รหัสผู้ประกอบการ', key: 'contractor_id' },
        { header: 'แบรนด์', key: 'brand_name' },
        { header: 'โมเดล', key: 'asset_model_code' },
        { header: 'ชื่อสินค้า', key: 'asset_model_name' },
        { header: 'ชื่อแบรนด์สินค้า', key: 'brand_name' },
        { header: 'ประเภทสินค้า', key: 'group_name' },
        { header: 'สถานะสินค้า', key: 'status' },
        { header: 'สถานะประกัน', key: 'warranty_status' },
        { key: 'actions', Name: "col-width" },
    ];


    useEffect(() => {
        setData({ rows: assetInstall || [], columns: columns });
    }, [assetInstall]);

    const handlePageChange = (newPage) => {
        setPaginationModel((prev) => ({ ...prev, page: newPage }));
    };

    const handleshowTicket = (data) => {
        setModalShow(true);
        setRows(data);
    }

    return (
        <Container fluid className="work-table-container">

            <ToolbarComponent setAction={setAction} Action={Action} token={token} />
            <Table hover responsive className="table-boder" size="sm">
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner animation="border" variant="primary" />
                    </div>
                ) : (
                    <>
                        <thead>
                            <tr>
                                {data.columns.map(col => (
                                    <th
                                        key={col.key}
                                        className={`work-table-header custom-background ${col.Name}`}
                                    >
                                        {col.header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody style={{ height: "fit-content" }}>
                            {data.rows
                                .slice(
                                    paginationModel.page * paginationModel.pageSize,
                                    (paginationModel.page + 1) * paginationModel.pageSize
                                )
                                .map(row => (
                                    <tr key={row.auto_id} className="work-table-row">
                                        {data.columns.map(col => (
                                            <td key={col.key} className={`work-table-cell ${col.Name}`}>
                                                {col.key === 'actions' ? (
                                                    <div
                                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                    >
                                                        <div
                                                            onClick={() => {
                                                                handleshowTicket(row);
                                                            }}
                                                            style={{
                                                                textAlign: 'center',
                                                                color: 'rgba(93, 135, 255, 1)',
                                                                borderBottom: '1px solid rgba(93, 135, 255, 1)',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            ดูรายละเอียด
                                                        </div>
                                                    </div>
                                                ) : col.key === 'index' ? (
                                                    data.rows.indexOf(row) + 1
                                                ) : col.key === "status" ? (
                                                    <>
                                                        {row[col.key] === 1 && (
                                                            <span className="work-status work-status-open">ปกติ</span>
                                                        )}
                                                        {row[col.key] === 2 && (
                                                            <span className="work-status work-status-close">ชำรุด</span>
                                                        )}
                                                        {row[col.key] === 3 && (
                                                            <span className="work-status work-status-pending">สูญหาย</span>
                                                        )}
                                                        {row[col.key] !== 1 && row[col.key] !== 2 && row[col.key] !== 3 && (
                                                            <span className="work-status work-status-close">ไม่ทราบสถานะ</span>
                                                        )}
                                                    </>
                                                ) : col.key === "warranty_status" ? (
                                                    <>
                                                        {row[col.key] === 1 && (
                                                            <span className="work-status work-status-open">อยู่รในประกัน</span>
                                                        )}
                                                        {row[col.key] !== 1 && (
                                                            <span className="work-status work-status-close">สิ้นสุดประกัน</span>
                                                        )}
                                                    </>
                                                ) : col.key === "brand_name" ? (
                                                    row[col.key] === "string" ? "Brand A" : row[col.key]
                                                ) : (
                                                    row[col.key]
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </>
                )}
            </Table>

            <AssetInstallModal
                setModalShow={setModalShow}
                show={modalShow}
                onHide={() => setModalShow(false)}
                centered
                backdrop="static"
                token={token}
                Data={rows}
                disabled={true}
            />

            <CustomPagination
                page={paginationModel.page}
                pageCount={Math.ceil(data.rows.length / paginationModel.pageSize)}
                onPageChange={handlePageChange}
                dataLength={data.rows.length}

            />
            {/* <AssetInstallModal show={modalShow} onHide={() => setModalShow(false)} setAction={setAction} Action={Action} token={token} /> */}
        </Container>

    );

}
