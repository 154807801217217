import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Table, Stack, Button, Modal } from 'react-bootstrap';




const PictureModal = ({ showModal, setShowModal,imageUrl }) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      backdrop="static"
      size='lg'
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>รูปภาพส่งงาน</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={imageUrl} alt='Bgcolore' style={{ width: '100%', height: '100%', maxHeight: '400px', objectFit: 'contain' }} />
        </div>
      </Modal.Body>
    </Modal>
  );
}
const FileListTable = ({
  Loaddraft, data, disabled, ImageData
}) => {
  console.log("ImageData", ImageData)
  const [files, setFiles] = useState([]); // For uploaded files
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (ImageData) {
      setFiles(ImageData);
    }
  }, [ImageData]);

  return (
    <Container>
      <Row className="my-4">
        <Col lg={4}>
          <Form.Group controlId="formFiles">
            <Form.Label>เพิ่มรูปภาพ</Form.Label>
            <Form.Control disabled={disabled} type="file" multiple />
          </Form.Group>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <Stack direction="horizontal" gap={3}>
            <Form.Group controlId="formDetails">
              <Form.Label>รายละเอียด</Form.Label>
              <Form.Control
                disabled={disabled}
                type="text"
              />
            </Form.Group>
            <Form.Group controlId="formRemarks">
              <Form.Label>หมายเหตุ</Form.Label>
              <Form.Control
                disabled={disabled}
                type="text"
              />
            </Form.Group>
            <Button disabled={disabled}>เพิ่ม</Button>
          </Stack>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table responsive hover>
            <thead>
              <tr>
                <th style={{backgroundColor: 'rgba(228, 231, 236, 1)', color:"rgba(71, 84, 103, 1)"}}>ลำดับ</th>
                <th style={{backgroundColor: 'rgba(228, 231, 236, 1)', color:"rgba(71, 84, 103, 1)"}}>ชื่อไฟล์</th>

                <th style={{backgroundColor: 'rgba(228, 231, 236, 1)', color:"rgba(71, 84, 103, 1)"}}>รายละเอียด</th>
                <th style={{backgroundColor: 'rgba(228, 231, 236, 1)', color:"rgba(71, 84, 103, 1)"}}>หมายเหตุ</th>
                <th style={{backgroundColor: 'rgba(228, 231, 236, 1)', color:"rgba(71, 84, 103, 1)"}}></th>
              </tr>
            </thead>
            <tbody>
              {/* Existing documents from API */}
              {files.map((document, index) => (
                <tr key={document.auto_id}>
                  <td>{index + 1}</td>
                  <td>
                    {document.file_name}
                  </td>
                  <td>{document.details}</td>
                  <td>{document.remarks}</td>
                  <td>
                    <div
                      href="#"
                      style={{  color: '#5d87ff', textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => {
                        setShowModal(true);
                      }}
                    // onClick={() => disabled ? null : handleDeleteDoc(document.auto_id)}
                    >
                      ดูรูปภาพส่งงาน
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            <PictureModal showModal={showModal} setShowModal={setShowModal} imageUrl={files[0]?.file_url} />
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default FileListTable;
