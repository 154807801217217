import React, { useEffect, useState } from 'react';
import {
    Button,
    Modal,
    Tab,
    Nav,
    Row,
    Col,
    Stack,
} from 'react-bootstrap';
import TicketData from "./tabs/TicketData";
import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';


function TicketModal(props) {

    const [activeTab, setActiveTab] = useState('TicketedData');
    const [formData, setFormData] = useState({

    });
    const [formDatacopy, setFormDatacopy] = useState({
        "Status": 1,
        "Draft": 1,
        "contractor_id": 6,
    });

    const [ticketProblems, setTicketProblems] = useState([

    ]);
    const [ticketProblemscopy, setTicketProblemscopy] = useState([
        {
            "ProductInstallation": 18,
            "Product_Problem": 1,
            "Comment": "",
            "Description": ""
        }
    ]);

    const tabOrder = ['TicketedData', 'RepairHistory'];
    // const [fileimg, setfileimg] = useState([]);
    // const [delImageList, setDelImageList] = useState([]);
    const token = Cookies.get('token');


    const handleConversionTicket = (data) => ({
        // Description: data.Description,
        // Remark: data.remark,
        TicketDate: data.TicketDate,
        ContractorID: formDatacopy.contractor_id,
        Draft: formDatacopy.Draft,
        Status: formDatacopy.Status

    });

    const handleConversionTicketPromblems = (data) => ({
        ProductInstallation: ticketProblemscopy[0].ProductInstallation,
        Product_Problem: ticketProblemscopy[0].Product_Problem,
        Description: data.Description,
        Comment: data.Comment,
        Remark: data.Remark
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSubmit = new FormData();
        const TicketJson = JSON.stringify(handleConversionTicket(formData));
        const TicketPromblemsJson = JSON.stringify(handleConversionTicketPromblems(ticketProblems));

        console.log("TicketJson", TicketJson);
        console.log("TicketPromblemsJson", TicketPromblemsJson);

        formDataToSubmit.append('ticket', TicketJson);
        formDataToSubmit.append('problems', TicketPromblemsJson);

        if (ticketProblems?.pic?.length > 0) {
            console.log("length", ticketProblems?.pic?.length);
            Array.from(ticketProblems?.pic).forEach((file) => {
                console.log("images", file);
                formDataToSubmit.append('images', file);
            });
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/customer/tickets`,
                formDataToSubmit,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            Swal.fire({
                icon: 'success',
                title: 'เพิ่มข้อมูลแจ้งซ่อมสินค้าสําเร็จ',
                timer: 3000,
                confirmButtonText: 'OK',
            })
                .then(() => {
                    props.setModalShow(false);
                    window.location.reload();
                })
        } catch (error) {
            const errorMessage = error.response ? error.response.data : error.message;
            console.error('Error:', errorMessage);
            Swal.fire({
                icon: 'error',
                title: 'ไม่สามารถเพิ่มข้อมูลแจ้งซ่อมสินค้าได้',
                text: `Bearer ${errorMessage}`,
                confirmButtonText: 'OK',
            })
        }
    };


    useEffect(() => {
        if (props.Action === 'view') {
            if (props.Data) {
                setFormData(props.Data);
                if (props.Data.TicketProblems) {
                    const TicketProblems = props.Data.TicketProblems[0]
                    setTicketProblems(TicketProblems)
                }
            }
        }
    }, [props.Data])



    return (
        <Modal
            {...props}
            size='xl'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ position: "absolute" }}
        >
            <Modal.Header closeButton style={{ padding: "20px 24px" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {(() => {
                        switch (props.Action) {
                            case 'edit':
                                console.log(props.Action)
                                return 'แก้ไข Ticket';
                            case 'view':
                                console.log(props.Action)
                                return 'ดูรายละเอียด';
                            case 'add':
                                console.log(props.Action)
                                return 'เพิ่มข้อมูลแจ้งซ่อมสินค้า';
                            default:
                                console.log(props.Action)
                                return 'เพิ่มข้อมูลแจ้งซ่อมสินค้า';
                        }
                    })()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "0 24px" }}>
                <Row>
                    <Col>
                        <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                            <Row>
                                <Col sm={12} lg={12} md={12}>
                                    <Nav variant="tabs" className="d-flex justify-content-between">
                                        <Stack direction='horizontal'>
                                            <Nav.Item>
                                                <Nav.Link eventKey="TicketedData">ข้อมูลใบแจ้งซ่อม</Nav.Link>
                                            </Nav.Item>

                                        </Stack>
                                    </Nav>
                                </Col>
                                <Col lg={12}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="TicketedData">
                                            <TicketData
                                                loading={props.loading}
                                                token={token}
                                                Action={props.Action}
                                                show={props.show}
                                                setFormData={setFormData}
                                                setTicketProblems={setTicketProblems}
                                                formData={formData}
                                                ticketProblems={ticketProblems}
                                                disabled={props.Action === 'view'}
                                            />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "center", display: "flex" }}>
                <div>
                    {activeTab !== tabOrder[tabOrder.length - 1] && (
                        <Button
                            disabled={props.Action === 'view'}
                            onClick={(e) => {
                                handleSubmit(e);
                            }}
                        >
                            บันทึก
                        </Button>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default TicketModal;
