import React, { useState, useEffect } from 'react';
import { Form, Button, Stack, Modal } from 'react-bootstrap';
import Bgcolore from "../../../../../../assets/img/printer.webp"
import Spinner from 'react-bootstrap/Spinner';

function ImageUploader({ setTicketProblems, singleImage = false, ticketProblems, disabled, show }) {
  console.log("ticketProblems", ticketProblems);
  const [selectedImages, setSelectedImages] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [delImageArray, setDelImageArray] = useState([]); // For deleted image auto_ids
  const [insertImages, setInsertImages] = useState([]); // For new images to insert
  const [nullimage, setNullimage] = useState('ยังไม่ได้เลือกไฟล์');
  const [showModal, setShowModal] = useState(false);
  const [Index, setIndex] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const ClearState = () => {
    setSelectedImages([]);
    setFileNames([]);
    setDelImageArray([]);
    setInsertImages([]);
    setNullimage('ยังไม่ได้เลือกไฟล์');
    setIndex(null);
  }
  const ShowImageModal = ({ showModal, setShowModal, imageUrl }) => {
    const [ModalImage, setModalImage] = useState("")

    useEffect(() => {
      if (imageUrl) {
        setModalImage(imageUrl)
      }
    }, [imageUrl])

    return (
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        size='lg'
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>รูปภาพที่อัพโหลด</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={ModalImage} alt='Bgcolore' style={{ width: '100%', height: '100%', maxHeight: '400px', objectFit: 'contain' }} />
          </div>
        </Modal.Body>
      </Modal>

    )
  };

  const selectImages = (event) => {
    const files = Array.from(event.target.files);

    if (singleImage) {
      const newImage = files[0];
      setSelectedImages([newImage]);
      setFileNames([newImage.name]);

      setInsertImages([newImage]);
      setTicketProblems((prevState) => ({
        ...prevState,
        pic: [newImage], // Update with the new image
      }));
    } else {
      setSelectedImages((prevImages) => [...prevImages, ...files]);
      setFileNames((prevNames) => [...prevNames, ...files.map((file) => file.name)]);

      setInsertImages((prevInsert) => [...prevInsert, ...files]); // Add to insert list
      setTicketProblems((prevState) => ({
        ...prevState,
        pic: [...(prevState.pic || []), ...files], // Update with new images
      }));
    }
  };

  const removeImage = (indexToRemove) => {
    const imageToRemove = selectedImages[indexToRemove];

    if (imageToRemove.auto_id) {
      console.log('Removing image with auto_id:', imageToRemove.auto_id);

      // Update delImageArray and ticketProblems state correctly
      setDelImageArray((prev) => {
        const updatedArray = [...prev, imageToRemove.auto_id];
        console.log('Updated del_image_array:', updatedArray);

        // Ensure del_image_array is updated within ticketProblems
        setTicketProblems((prevState) => ({
          ...prevState,
          del_image_array: updatedArray,
        }));

        return updatedArray;
      });
    }

    // Remove the selected image from the list
    const updatedImages = selectedImages.filter((_, index) => index !== indexToRemove);
    const updatedFileNames = fileNames.filter((_, index) => index !== indexToRemove);

    setSelectedImages(updatedImages);
    setFileNames(updatedFileNames);

    // Update ticketProblems with the remaining images
    setTicketProblems((prevState) => ({
      ...prevState,
      pic: updatedImages,
    }));
  };

  const handleButtonClick = () => {
    document.getElementById('fileInput').click(); // Programmatically click the hidden input
  };

  const handleModalShow = (index) => {
    setShowModal(true);
    setIndex(index);
  };

  useEffect(() => {
    setLoading(true); // Start loading when the effect runs

    if (ticketProblems?.pic) {
      setSelectedImages(ticketProblems.pic); // Load existing images
      setTimeout(() => {
        setLoading(false); // Stop loading after 2s delay
      }, 1000);
    } else if (ticketProblems?.Pictures) {
      const Images = ticketProblems.Pictures.map((item) => ({
        PicUrl: item.Pic
      }));
      setSelectedImages(Images);
      setTimeout(() => {
        setLoading(false); // Stop loading after 2s delay
      }, 1000);
    } else {
      setTimeout(() => {
        setLoading(false); // Stop loading if no images are found, after 2s delay
      }, 1000);
    }
  }, [ticketProblems]);


  useEffect(() => {
    if (show === false) {
      console.log("ClearState", show)
      ClearState();
    }
  }, [show]);

  return (
    <Stack direction="row" gap={0}>
      <Form.Group controlId="formFile" className="mb-3" style={{ position: 'relative' }}>
        <Form.Control
          id="fileInput"
          type="file"
          accept="image/*"
          multiple={!singleImage}
          onChange={selectImages}
          onClick={(e) => (e.target.value = '')} // Reset input for re-upload
          style={{
            display: 'none', // Hide the input
          }}
        />
        <Stack direction="horizontal" gap={2}>
          <Button onClick={handleButtonClick}
            disabled={disabled}
            style={{
              maxWidth: '300px',
              height: '36px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(237, 242, 255, 1)',
              color: '#333',

            }}>
            เลือกไฟล์
          </Button>
          <div
            style={{
              visibility: disabled ? 'hidden' : 'visible',
              whiteSpace: 'nowrap',
              maxWidth: '300px',
              height: '36px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '10px',
              paddingRight: '10px', // Added padding for better spacing
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '300px',
                color: '#333',
                visibility: disabled ? 'hidden' : 'visible',
              }}
            >
              {fileNames.length === 0 ? nullimage : fileNames.join(', ')}
            </div>
          </div>
        </Stack>
      </Form.Group>
      {
        disabled ?
          <div style={{ display: 'flex', flexWrap: 'wrap' }} className="mb-3">
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              selectedImages && selectedImages.map((image, index) => (
                <div key={index} style={{ position: 'relative', marginRight: '10px' }}>
                  <img
                    onClick={() => handleModalShow(index)}
                    src={loading ? Bgcolore : image.PicUrl}
                    alt={`Uploaded Preview ${index + 1}`}
                    style={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'cover',
                      borderRadius: '3px',
                      cursor: "pointer"
                    }}
                  />
                  {showModal && Index !== null && (
                    <ShowImageModal
                      showModal={showModal}
                      setShowModal={setShowModal}
                      imageUrl={selectedImages[Index].PicUrl}
                    />
                  )}
                </div>
              ))
            )}
          </div>
          :
          <div style={{ display: 'flex', flexWrap: 'wrap' }} className="mb-3">
            {selectedImages &&
              selectedImages.map((image, index) => (
                <div key={index} style={{ position: 'relative', marginRight: '10px' }}>
                  <img
                    src={image.Pic ? image.Pic : URL.createObjectURL(image)}
                    alt={`Upload Preview ${index + 1}`}
                    style={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'cover',
                      borderRadius: '3px',
                    }}
                  />
                  <Button
                    variant="danger"
                    size="sm"
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      borderRadius: '50%',
                      padding: '0 5px',
                      fontSize: '12px',
                    }}
                    onClick={() => removeImage(index)}
                  >
                    X
                  </Button>
                </div>
              ))}
          </div>
      }
    </Stack>
  );
}

export default ImageUploader;
