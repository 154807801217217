import React,{useEffect} from 'react'
import Main from '../../MainComponents/main';
import Cookies from 'js-cookie';
import 'swiper/css';
import 'swiper/css/pagination';
import GLightbox from 'glightbox';
import PureCounter from '@srexi/purecounterjs';
import AOS from 'aos';

function Home({isNavOpen, setIsNavOpen}) {
  console.log("token", Cookies.get('token'))
  useEffect(() => {
    new PureCounter();  // Initialize PureCounter when component mounts
  }, []);

useEffect(() => {

    AOS.init({   
        duration: 1000, // Animation duration
        easing: 'ease-in-out', // Animation easing
        once: true,
        mirror: false
    });
    AOS.refresh(); // รีเฟรช AOS หลังจากที่ DOM เปลี่ยน


}, []);

useEffect(() => {
    const lightbox = GLightbox({
      selector: '.glightbox' // Update this to match the elements you want to target
    });

    return () => {
      lightbox.destroy(); // Clean up the lightbox on component unmount
    };
  }, []);

  return (
      <Main />
  )
}

export default Home
