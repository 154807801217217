import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Row } from 'react-bootstrap';
import ImangeUpload from "./ImangeUpload";



const ImageUploadModal = ({
    show,
    rowData,
    data,
    setShowModal,
    disabled,
    formData,
    setFormData,
    rowdata,

}) => {

    const [imageData, setImageData] = useState([]);
    const [afterImageData, setAfterImageData] = useState([]);





    useEffect(() => {
        if (rowData) {
            if (rowData?.before_images && rowData?.before_images.length > 0) {
                setImageData(rowData.before_images)
            }
            if (rowData?.after_images && rowData?.after_images.length > 0) {
                setAfterImageData(rowData.after_images)
            }
        }
    },[rowData])

    return (
        <Modal show={show} onHide={() => setShowModal(false)} centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>ดูรูปภาพที่อัปโหลด</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group controlId="formBrand" className="mb-3">
                                <Form.Label>Brand:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={rowData?.brand || ''}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group controlId="formId" className="mb-3">
                                <Form.Label>รหัสสินค้า:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={rowData?.code || ''}
                                    disabled
                                />
                            </Form.Group>

                            <Form.Group controlId="formName" className="mb-3">
                                <Form.Label>ชื่อสินค้า:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={rowData?.name || ''}
                                    disabled
                                />
                            </Form.Group>

                            <Form.Group controlId="formSerialNumber" className="mb-3">
                                <Form.Label>S/N:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={rowData?.serial_number || ''}
                                    disabled
                                />
                            </Form.Group>

                            <Form.Group controlId="beforeRepairImage" className="mb-3" style={{ visibility: afterImageData?.pic?.length > 0 ? 'hidden' : 'visible' }}>
                                <Form.Label>รูปก่อนซ่อมแซม:</Form.Label>
                                <ImangeUpload
                                    disabled={disabled}
                                    setFormData={setFormData}
                                    tab="beforeRepair"
                                    data={imageData}
                                    setData={setImageData}
                                />
                            </Form.Group>

                            <Form.Group controlId="afterRepairImage" className="mb-3" style={{ visibility: imageData?.pic?.length > 0 ? 'hidden' : 'visible' }}>
                                <Form.Label>รูปหลังซ่อมแซม:</Form.Label>
                                <ImangeUpload
                                    disabled={disabled}
                                    setFormData={setFormData}
                                    tab="afterRepair"
                                    data={afterImageData}
                                    setData={setAfterImageData}
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>

            {/* <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>
                    Save
                </Button>
            </Modal.Footer> */}
        </Modal>
    );
};

export default ImageUploadModal;
