import React, { useState } from 'react';
import { Modal, Form, Col, Row } from 'react-bootstrap';
import ImangeUpload from "./ImangeUpload";
import axios from 'axios';
import Swal from 'sweetalert2';


const ImageUploadModal = ({
    show,
    rowData,
    data,
    setShowModal,
    disabled,
    formData,
    setFormData,
    rowdata,
    
}) => {
    const token = localStorage.getItem('spt');
    const [imageData, setImageData] = useState([]);
    console.log(imageData)
    const [afterImageData, setAfterImageData] = useState([]);
    console.log(afterImageData)
    console.log(data)
    console.log(rowData)




    const handleSubmit = async (e) => {
        e.preventDefault();
        let picType = 0;
        const formData = new FormData();
        formData.append('asset_list_id', data.auto_id);

        if (imageData.pic) {

            Array.from(imageData.pic).forEach((file) => {
                formData.append('files', file);
            });
            picType = 1;
        } else {

            Array.from(afterImageData.pic).forEach((file) => {
                formData.append('files', file);
            });
            picType = 2;
        }



        // Picture type(1 = before, 2 = after)
        console.log(picType)




        formData.append('pic_type', picType);
        formData.append('description', "test");
        // if (description) {
        //     formData.append('description', description);
        // }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/installation-docs/assets/${data.auto_id}/pictures`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`, // Include Bearer token for security
                },
            });

            // Show success alert
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'การอัปโหลดรูปภาพเสร็จสมบูรณ์',
                icon: 'success',
                timer: 2000,
                confirmButtonText: 'ตกลง'
            }).then(() => {
                setShowModal(false);
                setAfterImageData([]);
                setImageData([]);
                window.location.reload();

            })

            console.log('Success:', response.data);
        } catch (error) {
            // Show error alert
            Swal.fire({
                title: 'เกิดข้อผิดพลาด!',
                text: error.response?.data?.message || 'ไม่สามารถอัปโหลดรูปภาพได้',
                icon: 'error',
                confirmButtonText: 'ลองอีกครั้ง'
            });
            console.error('Error uploading pictures:', error.response?.data || error.message);
        }
    };

    return (
        <Modal show={show} onHide={() => setShowModal(false)} centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>ดูรูปภาพที่อัปโหลด</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group controlId="formBrand" className="mb-3">
                                <Form.Label>Brand:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={rowData?.brand || ''}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group controlId="formId" className="mb-3">
                                <Form.Label>รหัสสินค้า:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={rowData?.code || ''}
                                    disabled
                                />
                            </Form.Group>

                            <Form.Group controlId="formName" className="mb-3">
                                <Form.Label>ชื่อสินค้า:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={rowData?.name || ''}
                                    disabled
                                />
                            </Form.Group>

                            <Form.Group controlId="formSerialNumber" className="mb-3">
                                <Form.Label>S/N:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={rowData?.sn || ''}
                                    disabled
                                />
                            </Form.Group>

                            <Form.Group controlId="beforeRepairImage" className="mb-3" style={{ visibility: afterImageData?.pic?.length > 0 ? 'hidden' : 'visible' }}>
                                <Form.Label>รูปก่อนซ่อมแซม:</Form.Label>
                                <ImangeUpload
                                    disabled={disabled}
                                    setFormData={setFormData}
                                    tab="beforeRepair"
                                    data={imageData}
                                    setData={setImageData}
                                />
                            </Form.Group>

                            <Form.Group controlId="afterRepairImage" className="mb-3" style={{ visibility: imageData?.pic?.length > 0 ? 'hidden' : 'visible' }}>
                                <Form.Label>รูปหลังซ่อมแซม:</Form.Label>
                                <ImangeUpload
                                    disabled={disabled}
                                    setFormData={setFormData}
                                    tab="afterRepair"
                                    data={afterImageData}
                                    setData={setAfterImageData}
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>

            {/* <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>
                    Save
                </Button>
            </Modal.Footer> */}
        </Modal>
    );
};

export default ImageUploadModal;
