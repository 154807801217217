import React, {  useEffect } from 'react';
import {   Stack,  Container } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import TicketManagement from './Ticket_sys/TicketManagement/TicketManagementPage';
import GLightbox from 'glightbox';
import PureCounter from '@srexi/purecounterjs';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

function Ticket() {
    const {t} = useTranslation();
    const token = Cookies.get('token');
    console.log("token", token)
    useEffect(() => {
        new PureCounter();  // Initialize PureCounter when component mounts
      }, []);

    useEffect(() => {

        AOS.init({   
            duration: 1000, // Animation duration
            easing: 'ease-in-out', // Animation easing
            once: true,
            mirror: false
        });
        AOS.refresh(); // รีเฟรช AOS หลังจากที่ DOM เปลี่ยน


    }, []);

    useEffect(() => {
        const lightbox = GLightbox({
          selector: '.glightbox' // Update this to match the elements you want to target
        });
    
        return () => {
          lightbox.destroy(); // Clean up the lightbox on component unmount
        };
      }, []);


    return (
        <Container fluid className='main mt-5' style={{ padding: "0px" }}>
            <Stack direction='vertical' >
                <div className="page-title" data-aos="fade">
                    <div className="heading">
                        <div className="container">
                            <div className="row d-flex justify-content-center text-center">
                                <div className="col-lg-8">
                                    <h1>{t('ticket_management')}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><a href="/home">{t('home')}</a></li>
                                {/* <li>{t('services')}</li> */}
                                <li className='assetinstall'>{t('ticket_management')}</li>
                            </ol>
                        </div>
                    </nav>
                </div>
                <div data-aos="fade">
                <TicketManagement token={token} />
                </div>
            </Stack>
        </Container>
    )
}

export default Ticket
