import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Table, Stack } from 'react-bootstrap';
import Swal from 'sweetalert2';



const BrowseFileTable = (
  {
    formData, disabled, data
  }
) => {
  console.log(formData);
  const [files, setFiles] = useState([]); // For uploaded files
  // Handle file uploads
  const handleFileChange = (e) => {
    setFiles((prevFiles) => [...prevFiles, ...Array.from(e.target.files)]);
  };



  useEffect(() => {
    if (formData) {
      setFiles(formData);
    }
  }, [formData]);


  return (
    <Container>
      <Row className="my-4">
        <Col lg={4}>
          <Form.Group controlId="formFiles">
            <Form.Label>เพิ่ม List สินค้าที่จะติดตั้ง</Form.Label>
            <Form.Control
              type="file"
              multiple
              disabled={disabled}
              onChange={handleFileChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table responsive hover>
            <thead>
              <tr>
                <th  style={{backgroundColor: 'rgba(228, 231, 236, 1)', color:"rgba(71, 84, 103, 1)"}}>ลำดับ</th>
                <th  style={{backgroundColor: 'rgba(228, 231, 236, 1)', color:"rgba(71, 84, 103, 1)"}}>ชื่อไฟล์</th>
                <th  style={{backgroundColor: 'rgba(228, 231, 236, 1)', color:"rgba(71, 84, 103, 1)"}}>ขนาดไฟล์</th>
                {/* <th  style={{backgroundColor: 'rgba(228, 231, 236, 1)', color:"rgba(71, 84, 103, 1)"}}>ชนิดไฟล์</th> */}
                <th  style={{backgroundColor: 'rgba(228, 231, 236, 1)', color:"rgba(71, 84, 103, 1)"}}></th>
              </tr>
            </thead>
            <tbody>
              {/* Render Uploaded Files */}
              {files.map((file, index) => (
                <tr key={`file-${index}`}>
                  <td>{index + 1}</td>
                  <td>{file.file_name}</td>
                  <td>{file.file_size_formatted}</td>
                  {/* <td>{file.type || 'N/A'}</td> */}
                  <td style={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack gap={2} direction="horizontal">
                      <div
                        style={{
                          color: '#5d87ff',

                          borderBottom: '1px solid #5d87ff',  
                          visibility: files.length > 0 ? 'visible' : 'hidden',
                          cursor: 'pointer'
                        }}
                        onClick={() => Swal.fire({
                          title: 'คุณต้องการดาวโหลดไฟล์นี้หรือไม่?',
                          icon: 'question',
                          showCancelButton: true,
                          confirmButtonText: 'ยืนยัน',
                          cancelButtonText: 'ยกเลิก',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            const link = document.createElement('a');
                            link.href = file.file_url; // Directly using the URL if it's a string
                            link.download = file.file_url.split('/').pop(); // Extracts the filename from URL
                            document.body.appendChild(link); // Append to the body to ensure click works in all browsers
                            link.click();
                            document.body.removeChild(link); // Clean up after click
                          }
                        })}
                      >
                        ดาวโหลดเอกสาร
                      </div>

                      {/* <div
                        
                        style={{ color: 'red', borderBottom: '1px solid red' }}
                        // onClick={() => handleDeleteFile(index)}
                      >
                        ลบ
                      </div> */}
                    </Stack>
                  </td>
                </tr>
              ))}


              {/* {doc.map((document, index) => (
                <tr key={`doc-${document.auto_id}`}>
                  <td>{files.length + index + 1}</td>
                  <td>{document.file_name}</td>
                  <td>{document.file_size_formatted}</td>
                  <td>{document.file_name.split('.').pop()}</td>
                  <td>
                    <a
                      href="#"
                      style={{ color: 'red', borderBottom: '1px solid red' }}
                      onClick={() => disabled ? null : handleDeleteDoc(document.auto_id)}
                    >
                      ลบ
                    </a>
                  </td>
                </tr>
              ))} */}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default BrowseFileTable;
