import React from 'react'
import { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Ticketicon from '../../assets/img/sunpeakicon/ticketicon/Vector.svg'
import Ticketicon2 from '../../assets/img/sunpeakicon/assetIcon.svg'
import Ticketicon3 from '../../assets/img/sunpeakicon/totalicon.svg'
import Cookies from 'js-cookie';
import {  useNavigate } from 'react-router-dom';
import Reporticon from '../../assets/img/sunpeakicon/Reporticon.svg'
import { Stack } from 'react-bootstrap';
import GLightbox from 'glightbox';
import PureCounter from '@srexi/purecounterjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Main() {
    const { t } = useTranslation();
    const [user, setUser] = useState(false);
    const nevigate = useNavigate();

    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            setUser(true)
        }
    }, []);

    useEffect(() => {
        new PureCounter();  // Initialize PureCounter when component mounts
    }, []);

    useEffect(() => {

        AOS.init({
            duration: 1000, // Animation duration
            easing: 'ease-in-out', // Animation easing
            once: true,
            mirror: false
        });
        AOS.refresh(); // รีเฟรช AOS หลังจากที่ DOM เปลี่ยน


    }, []);

    useEffect(() => {
        const lightbox = GLightbox({
            selector: '.glightbox' // Update this to match the elements you want to target
        });

        return () => {
            lightbox.destroy(); // Clean up the lightbox on component unmount
        };
    }, []);

    const assetinstall = () => {
        nevigate('/assetinstallation')
    }

    const ticket = () => {
        nevigate('/ticket')
    }

    const login = () => {
        nevigate('/login')
    }

    const report = () => {
        nevigate('/report')
    }

    const total = () => {
        nevigate('/alltaskmanagement')
    }


    return (
        <main className='main'>

            <section id="hero" className="hero section dark-background">
                <img src="assets/img/hero-bg.jpg" alt="" data-aos="fade-in" />

                <div className="container">
                    <div className="row justify-content-center text-center" data-aos="fade-up" data-aos-delay="100">

                        {/* <h2>Powerful Digital</h2>
                        <h2>Solutions With <span>Court of Justice Asset Management.</span></h2> */}
                        <h2><span>Court of Justice Asset Management.</span></h2>
                        <p>By Sunpeak Platform</p>

                    </div>

                    <div className="row gy-4 mt-5 justify-content-center" data-aos="fade-up" data-aos-delay="500">
                        <div className="col-xl-2 col-md-4" data-aos="fade-up" data-aos-delay="300" onClick={user ? assetinstall : login}>
                            <Stack className="icon-box" direction="horizontal">
                                <div>
                                    <img
                                        src={Ticketicon2}
                                        alt="ticket"
                                        style={{
                                            width: "34px",
                                            height: "24.9px",
                                            position: "relative",
                                        }}
                                    />
                                </div>
                                <h3>
                                    <Link to="#">{t('asset_management')}</Link>
                                </h3>
                            </Stack>
                        </div>

                        <div className="col-xl-2 col-md-4" data-aos="fade-up" data-aos-delay="500" onClick={user ? ticket : login}>
                            <Stack className="icon-box" direction="horizontal">
                                <div>
                                    <img
                                        src={Ticketicon}
                                        alt="ticket"
                                        style={{
                                            width: "34px",
                                            height: "24.9px",
                                            position: "relative",
                                        }}
                                    />
                                </div>
                                <h3>
                                    <Link to="#">{t('ticket_management')}</Link>
                                </h3>
                            </Stack>
                        </div>

                        <div className="col-xl-2 col-md-4" data-aos="fade-up" data-aos-delay="500" onClick={user ? total : login}>
                            <Stack className="icon-box" direction="horizontal">
                                <img
                                    src={Ticketicon3}
                                    alt="ticket"
                                    style={{
                                        width: "auto",
                                        height: "auto",
                                        position: "relative",
                                    }}
                                />
                                <h3>
                                    <Link to="#">{t('total_management')}</Link>
                                </h3>
                            </Stack>
                        </div>

                        <div className="col-xl-2 col-md-4" data-aos="fade-up" data-aos-delay="500" onClick={user ? report : login}>
                            <Stack className="icon-box" direction="horizontal">
                                <img
                                    src={Reporticon}
                                    alt="ticket"
                                    style={{
                                        width: "auto",
                                        height: "auto",
                                        position: "relative",
                                    }}
                                />
                                <h3>
                                    <Link to="#">{t('report_management')}</Link>
                                </h3>
                            </Stack>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Main
