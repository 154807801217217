// SwitchExample.js
import React, { useTransition } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

function SwitchExample() {
  const { i18n } = useTranslation();
  const [isPending, startTransition] = useTransition();

  const handleLanguageChange = (event) => {
    startTransition(() => {
      i18n.changeLanguage(event.target.checked ? 'en' : 'th');
    });
  };

  return (
    <Form 
      style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        width: '100%', 
        marginTop: '1rem', 
        marginLeft: '30px' 
      }}
    >
      <Form.Check
        type="switch"
        id="language-switch"
        // label={i18n.language === 'en' ? 'Switch to Thai' : 'Switch to English'}
        checked={i18n.language === 'en'}
        onChange={handleLanguageChange}
        aria-label="Language switch"
      />
      <div style={{ marginTop: '0.5rem', fontWeight: 'bold' }}>
        {isPending ? 'Switching Language...' : (i18n.language === 'en' ? 'English' : 'ภาษาไทย')}
      </div>
    </Form>
  );
}

export default SwitchExample;
