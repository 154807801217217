import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function Respondible_tech({ setFormData, formData, disabled, Loaddraft }) {
  console.log(formData[1])


  return (
    <Container fluid className='contractor-container'>
      <Row>
        {/* Left Column */}
        <Col lg={6}>
          <Form>
            {/* Search Section */}
            <Row className="mb-3">
              <Col md={10}>
                <Form.Group controlId="search">
                  <Form.Label>เลือกช่าง</Form.Label>
                  <Form.Control 
                    name="technicians_id"
                    disabled
                    value={`${formData[0]?.first_name} ${formData[0]?.last_name}`}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={2}>
                <Button variant="primary" className="mt-4" disabled>ค้นหา</Button>
              </Col>
            </Row>

            {/* Form Fields */}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="contractorId">
                  <Form.Label>รหัสช่าง</Form.Label>
                  <Form.Control type="text" placeholder="รหัสผู้รับเหมา"
                    name='technician_code'
                    value={formData[0]?.technician_id || ""}
                    disabled />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="phone">
                  <Form.Label>เบอร์โทรศัพท์</Form.Label>
                  <Form.Control type="text" placeholder="เบอร์โทรศัพท์"
                    name='tel'
                    value={formData[0]?.tel || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="contactName">
                  <Form.Label>ชื่อช่าง</Form.Label>
                  <Form.Control type="text" placeholder="ชื่อผู้รับเหมา"
                    name='name'
                    value={`${formData[0]?.first_name} ${formData[0]?.last_name}`}
                    disabled />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Email"
                    name='email'
                    value={formData[0]?.email || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>เลขผู้เสียภาษี</Form.Label>
                  <Form.Control type="text" placeholder="เลขผู้เสียภาษี"
                    name='citizen_id'
                    value={formData[0]?.tax_id || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>ที่อยู่</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="ที่อยู่"
                    name='address'
                    value={formData[0]?.address || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6}>
                <Form.Group controlId="province" className="mb-3">
                  <Form.Label>จังหวัด</Form.Label>
                  <Form.Control type="text"
                    name='address'
                    value={formData[0]?.province_name || ""}
                    disabled>

                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="district" className="mb-3">
                  <Form.Label>เขต/ตำบล</Form.Label>
                  <Form.Control type="text"
                    name='address'
                    value={formData[0]?.district_name || ""}
                    disabled>

                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="subdistrict" className="mb-3">
                  <Form.Label>แขวง/ตำบล</Form.Label>
                  <Form.Control type="text"
                    name='address'
                    value={formData[0]?.sub_district_name || ""}
                    disabled>

                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col lg={6}>
          <Form>
            {/* Search Section */}
            <Row className="mb-3">
              <Col md={10}>
                <Form.Group controlId="search">
                  <Form.Label>เลือกลูกน้องช่าง</Form.Label>
                  <Form.Control 
                    name="technicians_id"
                    value={`${formData[1]?.first_name} ${formData[1]?.last_name}`}
                    disabled
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={2}>
                <Button variant="primary" className="mt-4" disabled >ค้นหา</Button>
              </Col>
            </Row>

            {/* Form Fields */}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="contractorId">
                  <Form.Label>รหัสช่าง</Form.Label>
                  <Form.Control type="text" placeholder="รหัสผู้รับเหมา"
                    name='technician_code'
                    value={formData[1]?.technician_id || ""}
                    disabled />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="phone">
                  <Form.Label>เบอร์โทรศัพท์</Form.Label>
                  <Form.Control type="text" placeholder="เบอร์โทรศัพท์"
                    name='tel'
                    value={formData[1]?.tel || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="contactName">
                  <Form.Label>ชื่อช่าง</Form.Label>
                  <Form.Control type="text" placeholder="ชื่อผู้รับเหมา"
                    name='name'
                    value={`${formData[1]?.first_name} ${formData[1]?.last_name}`}
                    disabled />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Email"
                    name='email'
                    value={formData[1]?.email || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>เลขผู้เสียภาษี</Form.Label>
                  <Form.Control type="text" placeholder="เลขผู้เสียภาษี"
                    name='citizen_id'
                    value={formData[1]?.tax_id || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>ที่อยู่</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="ที่อยู่"
                    name='address'
                    value={formData[1]?.address || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6}>
                <Form.Group controlId="province" className="mb-3">
                  <Form.Label>จังหวัด</Form.Label>
                  <Form.Control type="text"
                    name='address'
                    value={formData[1]?.province_name || ""}
                    disabled>

                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="district" className="mb-3">
                  <Form.Label>เขต/ตำบล</Form.Label>
                  <Form.Control type="text"
                    name='address'
                    value={formData[1]?.district_name || ""}
                    disabled>

                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="subdistrict" className="mb-3">
                  <Form.Label>แขวง/ตำบล</Form.Label>
                  <Form.Control type="text"
                    name='address'
                    value={formData[1]?.sub_district_name || ""}
                    disabled>

                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>

        {/* Right Column */}
        <Col lg={6}>

        </Col>
      </Row>
    </Container>
  )
}

export default Respondible_tech
