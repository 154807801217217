import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';


function Customerinstallation({ setFormData, formData, disabled, Loaddraft }) {
  console.log(formData)

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <Form>
            <Row className="mb-3">
              <Col >
                <Form.Group controlId="search">
                  <Form.Label>ค้นหาลูกค้า</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={formData?.customer_name || ''}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col >
                <Button variant="primary" className="mt-4" disabled>ค้นหา</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>รหัสลูกค้า</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name='id'
                    value={formData?.customer_id || ""}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>ชื่อลูกค้า</Form.Label>
                  <Form.Control type="text" placeholder=""
                    name='name'
                    value={formData?.customer_name || ''}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>เลขผู้เสียภาษี</Form.Label>
                  <Form.Control type="text" placeholder=""
                    name='tax_id'
                    value={formData?.tax_id || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>ที่อยู่</Form.Label>
                  <Form.Control as="textarea" rows={3}
                    name='address'
                    value={formData?.address || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>จังหวัด</Form.Label>
                  <Form.Control type="text" placeholder="จังหวัด"
                    name='province'
                    value={formData?.province_name || ""}
                    disabled />
                </Form.Group></Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>แขวง/ตำบล</Form.Label>
                  <Form.Control type="text" placeholder="แขวง/ตำบล"
                    name='district'
                    value={formData?.district_name || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>เขต/อำเภอ</Form.Label>
                  <Form.Control type="text" placeholder="เขต/อำเภอ"
                    name='sub_district'
                    value={formData?.sub_district_name || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>เบอร์โทรศัพท์</Form.Label>
                  <Form.Control type="text" placeholder="เบอร์โทรศัพท์"
                    name='tel'
                    value={formData?.tel || ""}
                    disabled />
                </Form.Group></Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control type="text" placeholder="E-mail"
                    name='email'
                    value={formData?.email || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col>
          <Form>
            <Row className="mb-3">
              <Col >
                <Form.Group controlId="search">
                  <Form.Label>สถานที่ติดตั้ง</Form.Label>
                  <Form.Control placeholder=""
                    name="site_id"
                    value={formData?.site_name}
                    disabled={disabled}
                    >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col >
                <Button variant="primary" className="mt-4" disabled>ค้นหา</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>รหัสสถานที่ติดตั้ง</Form.Label>
                  <Form.Control type="text" placeholder=""
                    name='id'
                    value={formData?.site_id || ""}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>ชื่อสถานที่ติดตั้ง</Form.Label>
                  <Form.Control type="text" placeholder=""
                    name='name'
                    value={formData?.site_name || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>ที่อยู่</Form.Label>
                  <Form.Control as="textarea" rows={3}
                    name='address'
                    value={formData?.site_address || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>จังหวัด</Form.Label>
                  <Form.Control type="text" placeholder="จังหวัด"
                    name='province'
                    value={formData?.site_province_name || ""}
                    disabled />
                </Form.Group></Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>แขวง/ตำบล</Form.Label>
                  <Form.Control type="text" placeholder="แขวง/ตำบล"
                    name='district'
                    value={formData?.site_district_name || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>เขต/อำเภอ</Form.Label>
                  <Form.Control type="text" placeholder="เขต/อำเภอ"
                    name='sub_district'
                    value={formData?.site_sub_district_name || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>เบอร์โทรศัพท์</Form.Label>
                  <Form.Control type="text" placeholder="เบอร์โทรศัพท์"
                    name='tel'
                    value={formData?.site_tel || ""}
                    disabled />
                </Form.Group></Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control type="text" placeholder="E-mail"
                    name='email'
                    value={formData?.site_email || ""}
                    disabled />
                </Form.Group>
              </Col>
            </Row>


          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default Customerinstallation
