// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ticket-title {
    display: flex;
    max-width: 420px;
    margin-bottom: 16px;
    border-bottom: 2px solid rgba(218, 0, 0, 0.49);
    margin-bottom: 17px;
    max-width: max-content;
  }

.Ticket-title h2 {  
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: 28px;
  font-weight: 600;
  color: #4A545E;
  line-height: 24px;
  text-align: left;
  width: 100%;
  white-space: nowrap;

}
  `, "",{"version":3,"sources":["webpack://./src/components/Page/Ticket/Ticket_sys/TicketManagement/TicketPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,8CAA8C;IAC9C,mBAAmB;IACnB,sBAAsB;EACxB;;AAEF;EACE,yCAAyC;EACzC,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;EACX,mBAAmB;;AAErB","sourcesContent":[".Ticket-title {\r\n    display: flex;\r\n    max-width: 420px;\r\n    margin-bottom: 16px;\r\n    border-bottom: 2px solid rgba(218, 0, 0, 0.49);\r\n    margin-bottom: 17px;\r\n    max-width: max-content;\r\n  }\r\n\r\n.Ticket-title h2 {  \r\n  font-family: 'Noto Sans Thai', sans-serif;\r\n  font-size: 28px;\r\n  font-weight: 600;\r\n  color: #4A545E;\r\n  line-height: 24px;\r\n  text-align: left;\r\n  width: 100%;\r\n  white-space: nowrap;\r\n\r\n}\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
