import React, { useState, useEffect } from 'react';
import Home from './components/Page/Home/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/HeaderComponent/Header';
import Footer from './components/FooterComponent/footer';
import Login from './components/Page/Login/Login';
import Register from './components/Page/Register/Regis';
import AssetInstall from './components/Page/AssetInstallation/AssetInstall';
import Ticket from './components/Page/Ticket/Ticket';
import Preload from './components/Preload/Preload';
import Scroll from "./components/Scroll/Scroll";
import About from './components/Page/About/About';
import Report from './components/Page/Report/Report';
import Total from './components/Page/Total/Total';
import "./App.css"
import axios from 'axios';
import Cookies from 'js-cookie';
import { I18nextProvider } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import i18n from "./I18n/I18n";
function App() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const token = Cookies.get('token');
  const [province, setProvince] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/locations/provinces`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        const provinceData = response.data;
        console.log(provinceData)
        setProvince(provinceData);
      } catch (error) {
        console.error("Failed to fetch tickets:", error);
      }
    };
    fetchData();
  }, []);// State to manage nav open/close

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <div className={`index-page scrolled ${isNavOpen ? 'mobile-nav-active' : ''}`}>
          <Header isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
          <div className='main-content'>
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/home" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register province={province} />} />
              <Route path="/assetinstallation" element={<AssetInstall />} />
              {/* <Route path="/" element={<Login />} /> */}
              <Route path="/ticket" element={<Ticket />} />
              <Route path="/about" element={<About />} />
              <Route path="/report" element={<Report />} />
              <Route path="/alltaskmanagement" element={<Total />} />
            </Routes>
          </div>
          <Footer />
          <Scroll />
          <Preload />
        </div>
      </Router>
    </I18nextProvider>

  );
}
export default App;
