// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploader-contractor {
    display: flex;
    align-items: center;
    border-style: none;
    background: rgba(237, 242, 255, 1);
    border-radius: 5px;
    width: 96px;
    height: 116px;
 /* Adjusts the form width to fit the content */
}

.imgup-contractor {
    border-style: none;
    max-width: 96px;
    max-height:116px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;   
}



.input-filed-contractor {
    width: fit-content;   
}
input[type=file]::file-selector-button {
  background-color: rgba(237, 242, 255, 1);  
}

.Stack-contarctor{
    display: flex;
    align-items: center;
    gap: 12px;
}

.contractor-container{
    margin-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Page/Total/TotalSystem/tabs/contractor.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,kCAAkC;IAClC,kBAAkB;IAClB,WAAW;IACX,aAAa;CAChB,8CAA8C;AAC/C;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB;;;;AAIA;IACI,kBAAkB;AACtB;AACA;EACE,wCAAwC;AAC1C;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".uploader-contractor {\r\n    display: flex;\r\n    align-items: center;\r\n    border-style: none;\r\n    background: rgba(237, 242, 255, 1);\r\n    border-radius: 5px;\r\n    width: 96px;\r\n    height: 116px;\r\n /* Adjusts the form width to fit the content */\r\n}\r\n\r\n.imgup-contractor {\r\n    border-style: none;\r\n    max-width: 96px;\r\n    max-height:116px;\r\n    padding-top: 8px;\r\n    padding-bottom: 8px;\r\n    padding-left: 15px;\r\n    padding-right: 15px;\r\n    position: absolute;   \r\n}\r\n\r\n\r\n\r\n.input-filed-contractor {\r\n    width: fit-content;   \r\n}\r\ninput[type=file]::file-selector-button {\r\n  background-color: rgba(237, 242, 255, 1);  \r\n}\r\n\r\n.Stack-contarctor{\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 12px;\r\n}\r\n\r\n.contractor-container{\r\n    margin-top: 24px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
