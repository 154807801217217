import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Searchicon from '../../../webproj/assets/img/searchicon.svg';
import axios from 'axios';
import "./checklistsearch.css"
import { useTranslation } from 'react-i18next';
function TicketSearch({ onSearch,token }) {
    const [searchParams, setSearchParams] = useState({
        contractor_id: '',
        customer_id: '',
        ticket_id: '',
        ticket_date: '',
        status: ''
    });
    const { t } = useTranslation();
    const [contractor, setContractor] = useState([]);
    const [searchcontactor, setsearchcontractor] = useState('');

  const fetchContractor = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/customer/contractors`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      const Data = response.data.data;
      setContractor(Data);
    } catch (error) {
      console.error("Failed to fetch contractors:", error);
      
    }
  };

    useEffect(() => {
        fetchContractor();
    }, []);

    const handledoubleClick = () => {
        setsearchcontractor('');
        setSearchParams({
            contractor_id: '',
            customer_id: '',
            ticket_id: '',
            ticket_date: '',
            status: ''
        });  // Trigger search in parent

    };

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === 'contractor_id') {
            setsearchcontractor(value);
        }
        setSearchParams((prev) => ({ ...prev, [name]: value }));
    };

    const handleSearch = () => {
        onSearch(searchParams);  // Trigger search in parent
    };

    return (
        <Container fluid className="checklistsearch-container container-style" style={{
            padding: '16px 24px',
            borderRadius: '12px',
            border: '1px solid rgba(230, 233, 244, 1)',
            boxShadow: '0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.2)',
            marginBottom: "20px",
            maxWidth: "1320px"
        }}
        >
            <Row>
                <Col>
                    <Form.Label className="search-header">{t('search')}</Form.Label>
                </Col>
            </Row>
            <Row style={{ marginBottom: "8px" }}>
                <Col>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>รหัส/ชื่อ ผู้ประกอบการ</Form.Label>
                        
                            <Form.Select
                                name="contractor_id"
                                onChange={handleInputChange}
                                value={searchcontactor}
                                
                            >
                                <option value="" selected disabled>เลือกผู้ประกอบการ</option>
                                {contractor?.map((item, index) => (
                                    <option key={index} value={item.contractor_name} index={index}>
                                        {item.contractor_name}
                                    </option>
                                ))}
                                {/* {contractor?.map((item, index) => (
                                    <option key={index} value={item.id} index={index}>
                                        {item.id}
                                    </option>
                                ))} */}
                            </Form.Select>
                        
                    </Form.Group>
                </Col>
                <Col>
                <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>สถานะใบงาน</Form.Label>
                        <div className="input-wrapper">
                            <Form.Select
                                name="status"
                                onChange={handleInputChange}
                                className="form-controlsearch"
                            >
                                <option value="">เลือกสถานะสถานะใบงาน</option>
                                <option value="1">รอแก้ไข</option>
                                <option value="2">กำลังแก้ไข</option>
                                <option value="3">แก้ไขสำเร็จ</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>รหัสใบงานแจ้งซ่อม</Form.Label>
                       
                            <Form.Control
                            
                                name="ticket_id"
                                className="input-wrapper"
                                onChange={handleInputChange}
                                
                            />
                       
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>เลือกวันที่แจ้งซ่อม</Form.Label>
                            <Form.Control
                                type="date"
                                name="ticket_date"
                                className="input-wrapper"
                                onChange={handleInputChange}
                            />
                       
                    </Form.Group>
                </Col>
                <Col
                    lg={1}
                    sm={0}
                    md={0}
                    className="button-container1"
                >
                    <Form.Group className='form-group'>
                        <Button
                            className="search-button"
                            onClick={handleSearch}
                            onDoubleClick={() => {
                                handledoubleClick();
                                onSearch(searchParams);  // Call search after resetting params
                            }}
                        >
                            <img src={Searchicon} alt='searchicon' />
                            <span>{t('search')}</span>

                        </Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row style={{ display: "none" }}>
                <Col>
                    <Form.Group className='form-group' style={{ visibility: "hidden" }}>
                        <Form.Label className='from-labelsearch'>สถานะ Ticket </Form.Label>
                        
                        <Form.Select
                                name="status"
                                onChange={handleInputChange}
                                
                            >
                                <option value="">สถานะ Ticket</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                            </Form.Select>
                        
                    </Form.Group>
                </Col>
                <Col sm={0} md={0} className='hidden-button'></Col>
                <Col sm={0} md={0} className='hidden-button'></Col>
                <Col sm={0} md={0} className='hidden-button'></Col>
                <Col
                    className="button-container2"
                    lg={1}
                >
                    <Form.Group className='form-group'>
                        <Button
                            className="search-button"
                            onClick={handleSearch}
                        >

                            <img src={Searchicon} alt="searchicon" />
                            <span>{t('search')}</span>

                        </Button>
                    </Form.Group>
                </Col>
                <Col sm={0} md={0} className='hidden-button2'></Col>
                <Col sm={0} md={0} className='hidden-button2'></Col>
                <Col sm={0} md={0} className='hidden-button2'></Col>
            </Row>
        </Container>
    )
}


export default TicketSearch
