import React, {useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Searchicon from '../../../Page/webproj/assets/img/searchicon.svg';

import "../../Ticket/Ticket_sys/TicketManagement/checklistsearch.css"
import { useTranslation } from 'react-i18next';
function AssetInstallSearch({ onSearch }) {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useState({
        contractor_id: '',
        brand_name: '',
        asset_model_code: '',
        asset_model_name: '',
        group_name: '',
        status: '',
        warranty_status: '',
        installation_date: '',


    })

    const handleSearch = () => {
        onSearch(searchParams);  // Trigger search in parent
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams((prev) => ({ ...prev, [name]: value }));
    };

    const ClearSearch = () => {
        const ClearSearchparams = {
            contractor_id: '',
            brand_name: '',
            asset_model_code: '',
            asset_model_name: '',
            group_name: '',
            status: '',
            warranty_status: '',
            installation_date: '',
        };
        setSearchParams(ClearSearchparams);
        onSearch(ClearSearchparams);
    };

    return (
        <Container fluid className="checklistsearch-container container-style" style={{
            padding: '16px 24px',
            borderRadius: '12px',
            border: '1px solid rgba(230, 233, 244, 1)',
            boxShadow: '0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.2)',
            marginBottom: "20px",
            maxWidth: "1320px"
        }}
        >
            <Row >
                <Col>
                    <Form.Label className="search-header">{t('search')}</Form.Label>
                </Col>
            </Row>
            <Row style={{ marginBottom: "8px" }}>
                <Col>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>รหัสผู้ประกอบการ</Form.Label>
                        <div className="input-wrapper">
                            <Form.Control
                                name='contractor_id'
                                value={searchParams.contractor_id}
                                onChange={handleInputChange}
                                className="form-controlsearch"
                            />
                        </div>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>ชื่อสินค้า</Form.Label>
                        <div className="input-wrapper">
                            <Form.Control
                                className="form-controlsearch"
                                name='asset_model_name'
                                value={searchParams.asset_model_name}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>แบรนด์</Form.Label>
                        <div className="input-wrapper">
                            <Form.Select
                                name='brand_name'
                                value={searchParams.brand_name}
                                onChange={handleInputChange}
                                className="form-controlsearch"
                            >
                                <option value=''>เลือกแบรนด์</option>
                                <option value='Canon'>Canon</option>
                                <option value='Epson'>Epson</option>
                                <option value='Fujitsu'>Fujitsu</option>
                                <option value='Hewlett Packard'>Hewlett Packard</option>
                                <option value='HP ENVY'>HP ENVY</option>
                                <option value='Lexmark'>Lexmark</option>
                                <option value='Nikon'>Nikon</option>
                                <option value='Olympus'>Olympus</option>
                                <option value='Panasonic'>Panasonic</option>
                                <option value='Sharp'>Sharp</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>ประเภท</Form.Label>
                        <div className="input-wrapper">
                            <Form.Select
                                name='group_name'
                                value={searchParams.group_name}
                                onChange={handleInputChange}
                                className="form-controlsearch"
                            >
                                <option value=''>เลือกประเภท</option>
                                <option value='Printer'>Printer</option>
                                <option value='เครื่องปรับอากาศรุ่น A'>เครื่องปรับอากาศรุ่น A</option>
                                <option value='เครื่องปรับอากาศรุ่น B'>เครื่องปรับอากาศรุ่น B</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col
                    lg={1}
                    sm={0}
                    md={0}
                    className="button-container1"
                >
                    <Form.Group className='form-group'>
                        <Button
                            onClick={handleSearch}
                            type="button"
                            onDoubleClick={ClearSearch}
                            className="search-button"
                        >
                            <img src={Searchicon} alt='Searchicon' />
                            <span>ค้นหา</span>

                        </Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row style={{ marginBottom: "8px" }}>
                <Col>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>สถานะสินค้า</Form.Label>
                        <div className="input-wrapper">
                            <Form.Select
                                name='status'
                                value={searchParams.status}
                                onChange={handleInputChange}
                                className="form-controlsearch"
                            >
                                <option value=''>เลือกสถานะ</option>
                                <option value='1'>ปกติ</option>
                                <option value='2'>ชำรุด</option>
                                <option value='3'>สูญหาย</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>สถานะประกัน</Form.Label>
                        <div className="input-wrapper">
                            <Form.Select
                                name='warranty_status'
                                value={searchParams.warranty_status}
                                onChange={handleInputChange}
                                className="form-controlsearch"

                            >
                                <option value=''>เลือกสถานะประกัน</option>
                                <option value='1'>อยู่ในประกัน</option>
                                <option value='0'>สิ้นสุดประกัน</option>
                            </Form.Select>

                        </div>
                    </Form.Group>
                </Col>
                <Col style={{ maxWidth: "300px" }}>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>วันที่ติดตั้ง</Form.Label>
                        <div className="input-wrapper">
                            <Form.Control
                                type='date'
                                name='installation_date'
                                value={searchParams.installation_date}
                                onChange={handleInputChange}
                                className="form-controlsearch"

                            />
                        </div>
                    </Form.Group>
                </Col>

                {/* <Col  style={{ maxWidth: "300px", visibility: "hidden" }}>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>เลขที่ใบงาน</Form.Label>
                        <div className="input-wrapper">
                            <Form.Control
                                className="form-controlsearch"

                            />
                        </div>
                    </Form.Group>
                </Col> */}

                <Col style={{ maxWidth: "300px", visibility: "hidden" }}>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>ประเภทใบงาน</Form.Label>
                        <div className="input-wrapper">
                            <Form.Control

                                className="form-controlsearch"

                            />
                        </div>
                    </Form.Group>
                </Col>

                <Col
                    className="button-container2"
                    lg={1}
                >
                    <Form.Group className='form-group'>
                        <Button
                            className="search-button"
                            onClick={handleSearch}
                            type="button"
                            onDoubleClick={ClearSearch}
                        >

                            <img src={Searchicon} alt='Searchicon' />
                            <span>ค้นหา</span>

                        </Button>
                    </Form.Group>
                </Col>
                <Col sm={0} md={0} className='hidden-button2'></Col>
                <Col sm={0} md={0} className='hidden-button2'></Col>
                <Col sm={0} md={0} className='hidden-button2'></Col>
            </Row>
            {/* <Row style={{ marginBottom: "8px" }}>
                <Col style={{ maxWidth: "300px" }}>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>วันที่ติดตั้ง</Form.Label>
                        <div className="input-wrapper">
                            <Form.Control
                                type='date'
                                name='installation_date'
                                value={searchParams.installation_date}
                                onChange={handleInputChange}
                                className="form-controlsearch"

                            />
                        </div>
                    </Form.Group>
                </Col>


                <Col style={{ maxWidth: "300px", visibility: "hidden" }}>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>สถานะประกัน</Form.Label>
                        <div className="input-wrapper">
                            <Form.Control
                                className="form-controlsearch"
                            />
                        </div>
                    </Form.Group>
                </Col>
                <Col style={{ maxWidth: "300px", visibility: "hidden" }}>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>สถานะประกัน</Form.Label>
                        <div className="input-wrapper">
                            <Form.Control
                                className="form-controlsearch"

                            />

                        </div>
                    </Form.Group>
                </Col>
                <Col style={{ maxWidth: "300px", visibility: "hidden" }}>
                    <Form.Group className='form-group'>
                        <Form.Label className='from-labelsearch'>สถานะประกัน</Form.Label>
                        <div className="input-wrapper">
                            <Form.Control
                                className="form-controlsearch"

                            />

                        </div>
                    </Form.Group>
                </Col>
                <Col
                    className="button-container2"
                    lg={1}
                >
                    <Form.Group className='form-group'>
                        <Button
                            className="search-button"
                            style={{ visibility: "hidden" }}
                        >

                            <img src={Searchicon} alt='Searchicon' />
                            <span>ค้นหา</span>

                        </Button>
                    </Form.Group>
                </Col>
            </Row> */}
        </Container>
    )
}

export default AssetInstallSearch
