import React, { useState, useEffect } from 'react';

import { Container, Row, Col, Form } from 'react-bootstrap';
import Uploader from './contractor_uploader';
import { FaStar } from 'react-icons/fa';

function Sheet({ setFormData, formData, disabled, Loaddraft, data, Action, show }) {
  console.log("formData", formData);
  console.log("data", data);
  const [rating, setRating] = useState(0);
  const [survey, setSurvey] = useState(0);
  const [hover, setHover] = useState(null);

  useEffect(() => {

    if (formData?.technician_score) {
      const rating = formData.technician_score;
      console.log("rating", rating);
      setRating(rating || 5);
    }

    if (formData?.require_survey) {
      if (formData?.require_survey === true) {
        setSurvey(1);
      }
      else {
        setSurvey(0);
      }
    }

  }, [formData]);




  return (
    <Container fluid className='contractor-container'>
      <Row >
        {/* Left Column */}
        <Col lg={8}>
          <Form>
            {/* Search Section */}
            <Row className="mb-3">
              <Form.Label>ต้องการงานสำรวจหรือไม่</Form.Label>


              <Col>
                <Form.Check
                  type="radio"
                  label="ต้องการ"
                  name="survey_request"
                  id="survey-yes"
                  value={1}
                  checked={survey === 1}

                  disabled={disabled}
                />
              </Col>
              <Col>
                <Form.Check
                  type="radio"
                  label="ไม่ต้องการ"
                  name="survey_request"
                  id="survey-no"
                  value={0}
                  checked={survey === 0}

                  disabled={disabled}
                />
              </Col>


            </Row>
            <Row className="mb-3">

              <Col md={6}>
                <Form.Group controlId="phone">
                  <Form.Label>เลขที่ใบงาน</Form.Label>
                  <Form.Control type="text" placeholder=""
                    name='sheet_id'
                    value={formData?.work_order_number}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Form Fields */}
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="formDate">
                  <Form.Label>วันที่ใบงาน</Form.Label>
                  <Form.Control
                    type="date"
                    name="doc_date"
                    value={formData?.install_start_date?.split('T')[0] || ''}
                    disabled={disabled}

                  />

                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formDate">
                  <Form.Label>วันที่ปิดงาน</Form.Label>
                  <Form.Control
                    type="date"
                    name='end_date'
                    value={formData?.install_end_date?.split('T')[0] || ''}
                    disabled={disabled}

                  />
                </Form.Group>
              </Col>
              <Col >
                <Form.Group controlId="contactName">
                  <Form.Label>สถานะ</Form.Label>
                  <Form.Select aria-label="Default select example"
                    name='status'
                    value={formData?.installation_status || ""}
                    disabled={disabled}

                  >
                    <option value="0"> ไม่ทราบสถานะ</option>
                    <option value="1">รอแก้ไข</option>
                    <option value="2">กำลังแก้ไข</option>
                    <option value="3">แก้ไขสำเร็จ</option>
                  </Form.Select>
                </Form.Group>
              </Col>\
            </Row>


            <Row>
              <Col md={4}>
                <Form.Group controlId="formStarRating">
                  <Form.Label>คะแนนช่าง</Form.Label>
                  <div style={{
                    display: 'flex', gap: '10px', marginBottom: '16px', borderRadius: "6px", border: "0.75px solid rgba(234, 239, 244)", justifyContent: "center",
                    backgroundColor: disabled ? "rgba(234, 239, 244)" : "white",
                  }}>
                    {[...Array(5)].map((_, index) => {
                      const starValue = index + 1;

                      return (
                        <FaStar
                          key={index}
                          size={32}
                          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                          color={starValue <= (hover || rating) ? '#ffc107' : '#e4e5e9'}
                          onClick={!disabled ? () => {
                            setRating(starValue);

                          } : null}
                          onMouseEnter={!disabled ? () => setHover(starValue) : null}
                          onMouseLeave={!disabled ? () => setHover(null) : null}
                        />
                      );
                    })}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Label>ลายเซ็นรับงาน</Form.Label>
                <Uploader imagePath={formData?.signature_image_url || ""} disabled={disabled} setFormData={setFormData} tab={"sheet_tab"} Action={Action} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>รายละเอียด</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder=""
                    name='description'
                    value={formData?.details || ""}
                    disabled={disabled}
                  />

                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>หมายเหตุ</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder=""
                    name='remark'
                    value={formData?.remarks || ""}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>

        {/* Right Column */}

      </Row>
    </Container>
  )
}

export default Sheet
