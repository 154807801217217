import React from 'react'
import {Link} from 'react-router-dom';

function Scroll() {
  return (
    <div>
        <Link to="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></Link>
    </div>
  )
}

export default Scroll
